@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.job-single-notification-settings {
	margin-bottom: 35px;

	.notification {

		.section-header {
			margin-bottom: 8px !important;
		}

		.section-title {
			position: relative;

			.title-loader {
				position: absolute;
				top: 4px;
				margin-left: 8px;
			}

			.btn-link {
				font-weight: $demiBold;
				position: absolute;
				right: 0;
				top: 0;

				@include breakpoint($mobile, $tablet) {
					position: relative;
					display: block;
				}
			}
		}

		.description {
			font-weight: $normal;
			color: $tunaDarkBlack;
		}

		.empty {
			font-style: italic;
			margin-top: 12px;
		}
	}

	.loader {
		display: flex;
		margin: 20px auto;
	}

}
