.facility-screen {
	width: 100%;
	height: 100%;

	.content {
		min-width: 100%;
		min-height: 100%;
		padding: 0;
	}

	.container {
		margin: {
			top: 72px;
		}
	}
}
