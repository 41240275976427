@import '../../../../styles/mixins';

.subject-editor {
	.label {
		color: $dustyGray;
		font-size: 10px;
		font-weight: $semiBold;
		text-transform: uppercase;
		margin-bottom: 8px;
	}
}

.body-editor {
	margin-top: 32px;
	width: 100%;

	:global {
		.rdw-editor-toolbar {
			border-top: none;
		}

		.rdw-option-wrapper {
			border-color: transparent;
		}
	}
}

.description {
	margin-top: 16px;
	font-style: italic;
	font-size: 12px;
	font-weight: $light;
}