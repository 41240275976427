.slider {
	display: inline-block;
	padding: {
		top: 38px;
	}

	:global {
		.rc-slider-mark {
			top: 0;
		}
	}

	@at-root &__wrap {
		display: block;

		.input {
			display: none;
		}
	}
}
