@import '../../../styles/mixins';

.wrap {
	margin: {
		top: 50px;
	}

	.title {
		font: {
			family: $roboto;
			size: 20px;
		}
	}

	.desc {
		font: {
			family: $roboto;
			size: 16px;
			weight: $normal;
			color: $comet;
		}
	}

	a {
		@extend %link;
	}
}
