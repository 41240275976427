@import '../../../../../styles/mixins';

.form {
	display: flex;
	flex-direction: column;

	.input {
		&:not(:last-child) {
			margin: {
				bottom: 40px;
			}
		}
	}

	.heading {
		color: $black;
	}

	.checkbox {
		@extend %signInFlowCheckboxStyle;

		.text {
			color: $black;
		}
	}
}
