@import '../../../../styles/variables';

.spinner {
	display: inline-block;
	max-width: 100%;
	max-height: 100%;
	width: 50px;
	height: 50px;
	border: 4px solid $downy;
	border: {
		radius: 50%;
	}
	border: {
		top: {
			color: $white;
		}
	}
	font: {
		size: 0;
	}
	transform: rotate(45deg);
	animation: spin 1s ease-out infinite;

	&.thin {
		border: {
			width: 2px;
		}
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(405deg);
	}
}
