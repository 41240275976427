@import 'variables';

//Here are placed places of styles code that are repeating through all project
@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin breakpoint($minResolution: null, $maxResolution: null) {
	@if $minResolution and $maxResolution {
		@media all and (min-width: $minResolution) and (max-width: $maxResolution - 1) {
			@content;
		}
	} @else if $minResolution {
		@media all and (min-width: $minResolution) {
			@content;
		}
	}
}

@mixin fixed($top: 0, $right: auto, $bottom: auto, $left: 0) {
	position: fixed;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin absolute($top: 0, $right: auto, $bottom: auto, $left: 0) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin autofill($textcolor: $white, $carretColor: $textcolor) {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: $textcolor;
		caret-color: $carretColor;
	}
}

//Styles for extending
%disabled-field {
	opacity: 0.2;

	&,
	* {
		cursor: default !important;
	}
}

%form-item-bottom-indent {
	&:not(:last-child) {
		margin: {
			bottom: 36px;
		}
	}
}

//Ui elements
%slash {
	&::before {
		content: '';
		position: relative;
		top: 2px;
		display: inline-block;
		height: 16px;
		width: 2px;
		background: {
			color: rgba($dustyGray, 0.6);
		}
	}
}

//Charts
%bar {
	@include absolute();
	display: block;
	height: 100%;
	border-radius: 0 50px 50px 0;
	background: linear-gradient(239.57deg, #37dbe0 20.53%, #4986a1 79.85%);
	overflow: hidden;
}

//TextButton
%button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none !important;
	background: {
		color: $downy;
	}
	color: $white;
}

%action {
	background: {
		color: transparent;
	}
	color: $downy;
}

%formSelect {
	.select-form-item {
		width: 100%;
	}

	.select {
		border: 0;
		width: 100%;
		padding: {
			left: 0;
		}
		background: transparent;
		font: {
			size: 14px;
			weight: $normal;
		}
		outline: none;
		color: $black;
	}

	.icon {
		@include absolute(auto, 0, 9px, auto);
		&::before {
			display: inline-block;
			content: '';
			width: 0;
			height: 0;
			border: {
				color: $abbey transparent transparent transparent;
				width: 8px 6px 0 6px;
				style: solid;
			}
		}
	}
}

%commonButtonStyles {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border: 1px solid $downy;
	font: {
		family: $openSans;
		size: 18px;
		weight: $bold;
	}
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.3s;
	outline: {
		offset: 4px;
		color: $downy;
	}

	&:not(.reversed) {
		@extend %button;

		&:hover,
		&:active {
			@extend %action;
		}
	}

	&.reversed {
		@extend %action;

		&:hover,
		&:active {
			@extend %button;
		}
	}

	&.white {
		@extend %action;
		border: {
			color: $white;
		}
		color: $white;

		&:hover,
		&:active {
			@extend %button;

			border: {
				color: $black;
			}
			background: {
				color: $white;
			}
			color: $black;
		}
	}

	&:not(.small) {
		min-height: 56px;
		min-width: 300px;
	}

	&.small {
		min-height: 42px;
		min-width: 180px;
		font: {
			size: 16px;
		}
	}
}

//Icon button
%iconButton {
	position: relative;
	display: inline-block;
	padding: 0;
	background: {
		color: transparent;
	}
	border: none;
	text-decoration: none;
	cursor: pointer;
	outline: {
		offset: 4px;
		color: $downy;
	}
}

//Clear fix
%clearfix {
	&::after {
		content: '';
		display: table;
		height: 0;
		width: 0;
		clear: both;
	}
}

//Error position styles
%errorPosition {
	@include absolute(auto, 0, -8px, 0);
	transform: translateY(100%);
}

%errorWrapper {
	border: {
		color: $bloodyRedApricot !important;
	}

	.label {
		color: $bloodyRedApricot;
	}
}

%buttonAsLink {
	border: 0;
	background: {
		color: transparent;
	}
	padding: 0;
	font: {
		family: $roboto;
		size: 16px;
	}
	color: $downy;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

%link {
	font: {
		weight: $bold;
	}
	text-decoration: none;
	color: $downy;

	&:hover {
		text-decoration: underline;
	}
}

//Templates
%slideFormBox {
	padding: 32px 0;
	border: {
		style: solid;
		width: 1px;
		color: $blackHaze;
		radius: 10px;
	}
	overflow: hidden;
	font: {
		family: $roboto;
		size: 16px;
		weight: $normal;
	}

	a {
		@extend %link;
	}
}

%content-shifting-up {
	margin: {
		top: -51px;
	}
}

%table-actions {
	> *:not(:last-child) {
		margin: {
			right: 24%;
		}
	}

	.duplicate::before {
		font: {
			size: 21px;
		}
	}
}

%table-preloader {
	@include absolute(50%, auto, auto, 50%);
	transform: translateX(-50%) translateY(-50%);
}

%visible-disable-styles {
	background: {
		color: transparent;
	}
	opacity: 0.5;
}

//Checkbox styles on sign in flow
%signInFlowCheckboxStyle {
	display: block;
	margin: {
		bottom: 35px;
	}
	padding: {
		left: 20px;
	}

	&.hidden {
		display: none;
	}
}

//Download item
%downloadItemElements {
	.title,
	.desc {
		font: {
			family: $roboto;
		}
		color: $tuna;
	}

	.desc {
		font: {
			size: 19px;
			weight: $normal;
		}
		color: $tuna;

		a {
			@extend %buttonAsLink;
			font: {
				size: 19px;
			}
			text-decoration: none;
		}
	}
}

@mixin termsAndCondition($color: $black) {
	&,
	* {
		color: $color;
	}

	&,
	*:not(i) {
		font: {
			size: 15px;
		}
	}

	a {
		@extend %link;
	}
}
