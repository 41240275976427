@import '../../../../styles/variables';

.tile {
	border: {
		radius: 16px;
	}
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
	background: {
		color: $white;
	}
	padding: 24px 16px;
	color: $tunaDark;
	text-align: center;
}
