@import '../../../../styles/mixins';

.wrap__content {
	width: 100%;
	height: 100%;
	padding: 0 !important;
}

.container {
	padding-top: 48px;
	display: flex;
	flex-direction: column;
    justify-content: space-between;
	height: calc(100vh - 200px);

	.description {
		color: $tuna;
		font-size: 16px;
		font-weight: $normal;
		margin-bottom: 30px;

		& > b {
			font-weight: $demiBold;
		}
	}

	.recipients {
		.label {
			font-size: 16px;
			line-height: 1.3;
			font-weight: $normal;
			color: $tuna;
		}

		.check-item {
			display: block;
			padding: 16px 0;

			& + .check-item {
				border-top: 1px solid $silver;
			}

			&.all {
				& + .check-item {
					border-color: $black;
				}
			}
		}
	}

	.loader {
		display: flex;
		margin: 20px auto;
	}

	.actions {
		.btn:first-child {
			margin-right: 8px !important;
		}
	}
}
