@import '../../../styles/mixins';

@keyframes contentAppearing {
	from {
		height: 0;
	}
	to {
		height: auto;
	}
}

.wrap {
	border: {
		top: {
			width: 1px;
			style: solid;
			color: $silver;
		}
	}
	box-sizing: border-box;
	padding: 16px;
	overflow: hidden;
	transition: all 250ms linear;

	&:first-letter {
		text-transform: uppercase;
	}

	&.hidden {
		padding: 0 16px;
		height: 0;
	}

	&:not(.hidden) {
		animation-name: contentAppearing;
		animation-delay: 150ms;
	}

	.text {
		font: {
			family: $openSans;
			weight: $demiBold;
			size: 18px;
		}
		color: $tuna;
		text-align: center;

		@include breakpoint($mobile, $tablet) {
			font-size: 14px;
		}
	}

	a {
		@extend %link;
		text-transform: uppercase;
	}
}
