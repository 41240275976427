@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.colored-number {
	font: {
		family: $openSans;
		size: 79px;
		weight: $light;
	}
	line-height: 1;

	@include breakpoint($mobile, $tablet) {
		font-size: 40px;
	}
}
