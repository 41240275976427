@import '../../../styles/variables';
@import '../../../styles/mixins';

.header-container {
	max-width: 100%;
	padding: 0 40px;

	@include breakpoint($mobile, $tablet) {
		padding: 0 20px;
	}
}

.header {
	position: relative;
	display: flex;
	align-items: flex-end;
	min-height: 74px;
	background: {
		color: $white;
	}

	.logo {
		margin: {
			top: auto;
			right: 10px;
			bottom: 4px;
		}
	}

	.logout-btn {
		display: flex;
		justify-content: flex-end;
		min-width: 150px;
		margin: {
			bottom: 15px;
			left: auto;
		}

		@include breakpoint($mobile, $tablet) {
			margin-top: 16px;
			margin-bottom: 50px;
		}

		&.without-credits {
			margin-bottom: 24px;
		}
	}

	.dropdown-toggle {
		text-align: right;

		.user,
		.spark-credits {
			font-size: 12px;

			@include breakpoint($mobile, $tablet) {
				font-size: 10px;
			}
		}

		.spark-credits {
			font-weight: $normal;
		}
	}

	.dropdown-content {
		flex-direction: column;
		align-items: center;
		right: 135px;
		max-width: 269px;
	}

	.knowledge-base {
		padding-left: 24px;
		margin-left: 14px;
		display: inline-flex;
		align-items: center;
		position: relative;
		margin-bottom: 8px;

		.knowledge-link {
			display: inline-block;
			height: 26px;
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: calc(50% - 8px);
			height: 18px;
			border-left: 1px solid $comet;
		}

		&.item-with-tooltip:hover {
			position: relative;
			cursor: pointer;

			.item-tooltip {
				display: block;
				z-index: 10;
				left: 0 !important;
			}
		}
	}
}
