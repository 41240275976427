@import '../../../../../styles/variables';

.job-details {
	.job-description {
		&.with-error {
			:global {
				.public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
					color: $bloodyRedApricot;
				}
			}
		}
	}

	.desired-skills {
		margin-bottom: 50px;
	}

	.loader {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.actions {
	text-align: center;
	margin-bottom: 40px;

	.btn + .btn {
		margin: 16px 0 40px;
	}

	.btn {
		&.sparkling {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				height: 24px;
				width: 26px;
				left: 10%;
				top: calc(50% - 12px);
				background-image: url('../../../../../assets/icons/sparkling-stars.svg');
				background-repeat: no-repeat;
				background-size: contain;
			}

			&:hover {
				&:before {
					background-image: url('../../../../../assets/icons/sparkling-stars-accent.svg');
				}
			}
		}
	}
}

.error {
	margin-top: 8px;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
}


