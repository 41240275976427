@import '../../styles/variables';

.promote-job {
	.header {
		background: {
			color: $comet;
		}
	}
	.form {
		margin: {
			bottom: 40px;
		}
	}

	.logo {
		max-width: 216px;

		img {
			max-width: 100%;
		}
	}

	.content {
		padding: {
			top: 40px;
			bottom: 80px;
		}
	}

	.desc {
		margin: {
			bottom: 28px;
		}
	}

	.common__desc {
		margin: {
			bottom: 32px;
		}
	}

	.link__item {
		margin: {
			bottom: 35px;
		}
	}
}

.loader {
	display: flex;
	margin-top: 5%;
}