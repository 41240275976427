@import '../../../../styles/mixins';

.nav-link {
	font-weight: $demiBold;
	text-decoration: none;
	color: $downy !important;

	&:hover {
		text-decoration: underline;
	}
}
