@import 'styles/fonts';
@import 'styles/variables';

html,
body,
#root {
	height: 100%;
	margin: 0;
	font: {
		family: $roboto;
	}

	* {
		box-sizing: border-box;
		outline: {
			color: $downy;
		}
	}
}

//Grid
@for $i from 1 through 12 {
	.column-#{$i} {
		width: 100% / 12 * $i;
		float: left;
		padding: {
			left: 10px;
			right: 10px;
		}
	}
}

//Scroll prohibiting
.non-scrollable {
	overflow: hidden;
}

//Form
.form-item {
	&:not(.disable-bottom-margin) {
		margin: {
			bottom: 40px;
		}
	}

	&.disable-bottom-margin {
		margin: {
			bottom: 0;
		}
	}

	&.small-bottom-margin {
		margin: {
			bottom: 17px;
		}
	}

	&.text-area {
		margin: {
			bottom: 55px;
		}
	}

	&:not(.wide):nth-of-type(2n) {
		margin: {
			left: 10px;
		}
	}
}

//Text editor
.rdw-editor-toolbar {
	margin: 0;
	padding: 10px;
	border: {
		top: 1px solid rgba($black, 0.2);
		right: 0;
		bottom: 0;
		left: 0;
	}

	.rdw-option-wrapper {
		transition: all 0.25s;

		&:hover {
			background: {
				color: rgba($downy, 0.8);
			}
		}

		&.rdw-option-active {
			background: {
				color: rgba($apricot, 0.8);
			}
		}
	}
}

.rdw-editor-main {
	padding: 14px 12px;
	border: {
		style: solid;
		width: 1px;
		color: rgba($black, 0.7);
		radius: 0;
	}

	font: {
		family: $openSans;
		size: 14px;
		weight: $normal;
	}

	.public-DraftStyleDefault-block {
		margin: {
			top: 0;
		}
	}

	a {
		color: $downy !important;
		text-decoration: none;
		font-weight: $semiBold;
	}

	.rdw-link-decorator-icon {
		display: none !important;
	}
}

.disabled {
	opacity: 0.5;
	outline: none;
	cursor: default;
}

//Common

.hidden-element {
	display: none !important;
}

[class*='candidate-rejection'],
[class*='company-settings'] {
	.rdw-editor-toolbar {
		border-top: none;
	}

	.react-wysiwyg-typescript-editor {
		min-height: 220px;
	}

	.rdw-option-wrapper {
		border-color: transparent;
	}
}

.height-100 {
	height: 100%;
}
