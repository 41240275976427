@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.ellipsis-wrap {
	white-space: nowrap;
}

.link {
	@extend %link;
	color: $downy !important;
	white-space: nowrap;

	&:before {
		display: none !important;
	}

	&.as-text {
		color: $tunaDark !important;
		font-weight: $normal !important;
		text-decoration: none;
	}
}

.item {
	&.item-with-tooltip {
		line-height: 1;

		&:hover {
			position: relative;
			cursor: pointer;

			.item-tooltip {
				display: block;
				z-index: 10;
			}
		}
	}

	.with-overflow {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&.as-link {
			color: $downy;

			&.as-text {
				color: $tunaDark;
			}
		}
	}
}
