@import '../../../../styles/mixins';

.commute-score {
	display: flex;
	flex-direction: column;
	align-items: center;

	.specific-emoji-commute-score {
		background-repeat: no-repeat;
		background-size: contain;

		&.extra-small-size {
			width: 15px;
			height: 16px;
		}

		&.small-size {
			width: 17px;
			height: 19px;
		}

		&.medium-size {
			width: 22px;
			height: 24px;
		}

		&.large-size {
			width: 62px;
			height: 67px;
		}

		&.low {
			background: url('../../../../assets/icons/commute/commute-low.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}

		&.medium {
			background: url('../../../../assets/icons/commute/commute-medium.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}

		&.high {
			background: url('../../../../assets/icons/commute/commute-high.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.text {
		font: {
			family: $openSans;
			size: 10px;
			weight: $demiBold;
		}
		color: $comet;
		text-align: center;
		margin-top: 3px;
	}
}
