@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.job-notifications-settings {
	margin-bottom: 10px;
	
	.notifications-title {
		margin-top: 48px;
		margin-bottom: 32px;
		font-size: 20px;
		font-weight: $demiBold;
		letter-spacing: 0.45px;
		color: rgba($tuna, 0.55);
	}


}
