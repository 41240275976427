@import '../../../../styles/mixins';

.extended-fit-score {
	margin-bottom: 24px;
	
	.scores {
		display: flex;
		flex-direction: row;
		margin-top: 20px;

		.score {
			position: relative;

			& + .score {
				margin-left: 14px;

				&:before {
					content: '+';
					color: $comet;
					font-size: 15px;
					font-weight: $demiBold;
					position: absolute;
					top: 0;
					left: -12px;
				}
			}
		}
	}
}
