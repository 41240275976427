@import '../../../../styles/mixins';

.wrap {
	width: 358px;
	min-height: 250px;
	padding: 20px 23px 40px;

	form {
		min-height: 350px;
	}

	.terms {
		@include termsAndCondition();
	}
}

.input {
	width: 100%;
	color: $black;
}

.terms {
	@include termsAndCondition();
}

.box {
	border: {
		color: $tuna;
	}
	input {
		-webkit-text-fill-color: $black !important;
		caret-color: $black !important;
	}
}

.actions {
	display: flex;
	align-items: center;
	margin: auto auto 5px;

	*:not(:last-child) {
		margin: {
			right: 11px;
		}
	}
}

.link {
	position: relative;

	&.clicked {
		&:after {
			transition: all 0.2s linear;
			position: absolute;
			content: url('../../../../assets/icons/check.svg');
			height: 10px;
			color: $downy;
			margin-left: 5px;
			opacity: 0;
		}

		&.clicked {
			&:after {
				opacity: 1;
			}
		}
	}
}
