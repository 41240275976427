@import '../../../../styles/variables';

.input-filter {
	position: relative;

	.clear {
		position: absolute;
		top: calc(50% - 14px);
		right: 0;
		width: 17px;
		height: 17px;
		cursor: pointer;
		border-radius: 50%;
		transition: all linear 0.2s;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1px;
			height: 10px;
			background: $doveGray;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}
