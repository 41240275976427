.text-grabber {
	.actions {
		display: flex;
	}

	.grabber {
		flex-grow: 1;
	}

	.btn {
		margin: {
			left: 9px;
		}
	}
}
