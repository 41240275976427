@import '../../../../styles/variables';

.list-item {
	margin: {
		bottom: 5px;
	}
	font: {
		family: $openSans;
		size: 14px;
		weight: $normal;
	}

	&:before {
		display: inline-block;
		margin: {
			right: 5px;
		}
		content: '•';
	}

	&.check {
		&:before {
			content: url('../../../../assets/icons/check.svg');
		}
	}
}
