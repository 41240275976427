@import '../../../styles/variables';

.wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	border: {
		radius: 50%;
	}
	font: {
		family: $openSans;
		size: 12px;
		weight: $semiBold;
	}
	color: $white;
}
