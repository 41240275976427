@import '../../../styles/variables';

.container {
	max-width: 1250px;
	padding: {
		left: 24px;
		right: 24px;
	}
	width: 100%;
	margin: {
		left: auto;
		right: auto;
	}

	&.flex {
		display: flex;
	}

	&.fluid {
		max-width: 100%;
		padding: {
			left: 70px;
			right: 70px;
		}
	}
}
