@import '../../../styles/mixins';

.date-picker {
	border: 0;
	background: {
		color: transparent;
	}
	padding: 10px 0;
	outline: 0;

	input {
		width: 100% !important;
	}
}

:global(.react-datepicker-wrapper) * {
	font: {
		family: $roboto;
		size: 14px;
		weight: $normal;
	}
}

:global(.react-datepicker-popper) {
	left: 50% !important;
}

:global(.react-datepicker-popper) {
	&[data-placement='top-start'] {
		transform: translateX(-50%) translateY(-36px) !important;
	}

	&[data-placement='bottom-start'] {
		transform: translateX(-50%) translateY(36px) !important;

		:global(.react-datepicker__triangle) {
			margin: {
				top: -7px;
			}
		}
	}
}

:global(.react-datepicker__triangle) {
	left: 50% !important;
	transform: translateX(-50%) !important;
}

:global(.react-datepicker-wrapper),
.date-picker {
	width: 100%;
}

:global(.react-datepicker__day--selected),
:global(.react-datepicker__day--keyboard-selected),
:global(.react-datepicker__month-text--keyboard-selected),
:global(.react-datepicker__quarter-text--keyboard-selected),
:global(.react-datepicker__year-text--keyboard-selected) {
	background: {
		color: $downy !important;
	}
}
