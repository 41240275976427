@import '../../../../styles/mixins';

.content {
	background: {
		color: white;
	}
	padding: {
		top: 32px;
		bottom: 32px;
		left: 0;
		right: 0;
	}
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	text-align: center;

	@include breakpoint($mobile, $tablet) {
		width: 95%;
	}
}

.form {
	display: flex;
	flex-direction: column;
	padding: {
		left: 32px;
		right: 32px;
	}

	.title {
		font-size: 24px;
		margin-bottom: 24px;
		color: $tuna;
		font-family: $openSans;
	}

	.text {
		font-size: 14px;
		font-weight: $normal;
		color: $tuna;
		line-height: 1.4;

		&.with-popup {
			&.flex {
				display: inline-flex;
			}
		}

		.highlighted {
			font-weight: $semiBold;
		}

		&.small {
			font-size: 12px;
		}

		.link {
			@extend %link;
			font-weight: $demiBold;
		}

		.error {
			color: $bloodyRedApricot;
			font-style: italic;
			margin-bottom: 4px;
		}
	}

	.form-item {
		& + .form-item {
			margin-top: 24px;
			padding-top: 24px;
			border-top: 1px solid $silver;
		}

		.item-part {
			font-size: 16px;

			& + .item-part {
				margin-left: 8px;
			}

			&.select {
				z-index: 1;
			}

			select {
				padding-right: 8px !important;
				padding-bottom: 2px !important;
				font-size: 16px;
				color: $wedgewood;
			}

			.select-icon {
				top: 10px !important;
			}
		}
	}

	.period-group {
		display: flex;

		& > *:first-child {
			margin-right: 16px;
			margin-bottom: 0;
		}

		label {
			color: $tunaDarkBlack;
		}
	}

	.btn {
		margin: 24px auto 0;

		&:disabled {
			background-color: $silverLight;
			border-color: $silver;
		}

		@include breakpoint($mobile, $tablet) {
			min-width: 100% !important;
		}
	}

	.info-box {
		margin-left: 12px;
		line-height: 1;

		&.inline {
			display: inline;
		}
	}

	.info-content {
		text-align: left;
		line-height: 1.4;

		.title {
			font-size: 14px;
			font-weight: $demiBold;
			margin-bottom: 8px;
		}

		.text {
			font-size: 14px;
			line-height: 20px;
		}

		.learn-more {
			font-size: 14px;
			margin-top: 8px;
			display: inline-block;
		}
	}

	.popover-width {
		width: 325px !important;
		min-width: 325px !important;
		left: -100px;
		top: 20px;
	}
}

.contact-section {
	margin-top: 24px;
	padding-top: 24px;
	border-top: 1px solid $pastelGreen;

	& > .title {
		font-size: 18px;
		font-weight: $demiBold;
		margin-bottom: 8px;
		font-family: $openSans;
		margin-top: 16px;
	}
}

.loader {
	margin: 90px auto 40px;
}

.success-message,
.error-message {
	color: $tuna;
	padding: {
		left: 38px;
		right: 38px;
		bottom: 16px;
	}

	.title {
		font-size: 36px;
		margin-bottom: 24px;
		font-family: $openSans;
	}

	.description {
		font-size: 16px;
		line-height: 1.4;
	}

	.btn {
		display: block;
		margin: 48px auto 0;
		width: 272px !important;
		min-width: 272px !important;
		max-width: 272px !important;

		@include breakpoint($mobile, $tablet) {
			min-width: 100% !important;
		}
	}
}
