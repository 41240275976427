.input {
	&:not(:last-child) {
		margin: {
			bottom: 40px;
		}
	}
}

.actions {
	flex-direction: column;
}

.error {
	margin: {
		bottom: 20px;
	}
}
