@import '../../../../styles/variables';

.icon {
	&::before {
		display: inline-block;
		width: 8px;
		height: 2px;
		margin: {
			left: 0 !important;
		}
		background: {
			color: $black;
		}
		content: '';
	}
}
