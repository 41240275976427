@import '../../../../styles/mixins';

.summary {
  margin: {
    bottom: 10px;
  }
  &.with-top-border {
    border: {
      top: {
        width: 1px;
        style: solid;
        color: $silver;
      }
    }
  }

  &.with-bottom-border {
    border: {
      bottom: {
        width: 1px;
        style: solid;
        color: $silver;
      }
    }
  }
  line-height: 1.4;

  &__section {
    display: block;
    padding: 15px 0 25px 0px;
  }

  &__text {
    font: {
      family: $roboto;
    }
    font-style: italic;
    color: $tuna;
  }
}
