@import '../../../styles/variables';
@import '../../../styles/mixins';

.wrapper {
    height: 100%;

    &.as-modal {
        @include fixed(0, 0, 0, 0);
        z-index: 5;
    }
}