@import '../../../../styles/mixins';

.title {
	margin: {
		top: 0;
		bottom: 0;
	}
	font: {
		family: $roboto;
		size: 44px;
		weight: $normal;
	}
	color: $tuna;

	@include breakpoint($mobile, $tablet) {
		font-size: 34px;
	}
}

.quick-actions {
	display: inline-flex;

	& > * {
		display: inline-flex;
		vertical-align: middle;
		margin: 0 8px;
		cursor: pointer;
	}

	.grab-link {
		position: relative;

		i::before {
			transition: color 350ms;
		}

		&:hover {
			text-decoration: none;

			i::before {
				color: $doveGray;
			}
		}
	}

	.item-with-tooltip:hover {
		position: relative;
		cursor: pointer;

		.item-tooltip {
			display: block;
			z-index: 10;
		}
	}
}
