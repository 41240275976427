@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.typography-text-preview {
	display: inline-flex;
	align-items: center;

	.input {
		width: 100%;
		height: 44px;
		border: 0;
		border: {
			radius: 5px;
		}
		padding: 13px 18px;
		background: {
			color: $blackHaze;
		}
		overflow-y: auto;
		font: {
			family: $roboto;
			size: 12px;
			weight: $normal;
		}
	}
}
