@import '../../../../styles/mixins';

.wrap {
	.table-body {
		min-height: calc(100vh - 310px);
	}

	.top-bar {
		margin: {
			top: 0;
		}
		padding: 0;
	}

	.preloader {
		@extend %table-preloader;
	}

	.actions {
		@extend %table-actions;
	}
}

.error-popup-content {
	display: flex;
	align-items: center;
	justify-content: center;
}
