@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.job-settings {
	margin-top: 50px;

	.settings-section {
		margin-bottom: 48px;

		.status-info {
			margin-bottom: 16px;

			.text {
				font-size: 14px;

				.highlight {
					font-weight: $demiBold;
					font-style: italic;
				}
			}
		}
	}

	.profile-name {
		font-size: 16px;
		font-weight: $normal;
		color: $tuna;
	}

	.popover-width {
		max-width: 400px !important;
		top: 18px;
	}

	.info-box {
		margin-left: 12px;
		display: inline;
		line-height: 16px;
	}

	.info-content {
		.title {
			font-size: 14px;
			font-weight: $demiBold;
			margin-bottom: 8px;
		}

		.text {
			font-size: 14px;
			line-height: 20px;
		}
	}

	.loader {
		display: flex;
		margin: 20px auto;
	}

	.section-header {
		font-size: 20px;
		font-weight: $demiBold;
	}
}

.confirmation-modal {
	width: 387px !important;
}

.modal-title {
	font-size: 28px !important;
	font-weight: $normal !important;
	margin-bottom: 16px !important;
}

.modal-description {
	text-align: center;

	.text {
		.highlighted {
			font-weight: $demiBold;
		}
	}
}
