@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.tab {
	border: {
		top: 0;
		right: 0;
		bottom: 4px solid transparent;
		left: 0;
	}
	min-width: 160px;
	padding: 15px;
	background: {
		color: transparent;
	}
	font: {
		family: $openSans;
		size: 12px;
		weight: $bold;
	}
	color: $mineShaft;
	cursor: pointer;
	text-transform: uppercase;
	outline: none;

	@include breakpoint($mobile, $desktop) {
		min-width: 90px;
		padding: 16px 8px;
	}

	@include breakpoint($mobile, $tablet) {
		min-width: 80px;
		font-size: 11px;
	}

	&:not(.active):focus-visible {
		color: $downy;
	}

	&:not(:last-child) {
		margin: {
			right: 8px;
		}
	}

	&.active {
		border: {
			bottom: {
				color: $apricot;
			}
		}
	}
}
