@import '../../../../styles/variables';

.chip {
	cursor: pointer;
	background: $wedgewood;
	border-radius: 8px;
	padding: 8px 16px;
	display: flex;
	flex-direction: row;
    flex-wrap: nowrap;
	align-items: center;
	margin: 4px 8px;

	.checkbox {
		margin-right: 8px;
	}

	.text {
		font: {
			family: $openSans;
			size: 14px;
			weight: $normal;
		}
		color: $white;
	}
}
