@import '../../../../styles/variables';

.form-actions {
	margin: {
		top: 70px;
	}

	&.centered-content {
		text-align: center;

		*:not(:last-child) {
			margin: {
				right: 24px;
			}
		}
	}
}
