@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.table-cell {
	position: relative;
	display: inline-flex;
	align-items: center;
	flex: 1;
	padding: 8px 12px;
	min-height: 52px;
	border: {
		bottom: 0.5px solid $silverChalice;
	}
	font: {
		family: $roboto;
		size: 14px;
	}
	color: $tuna;

	@include breakpoint($mobile, $tablet) {
		flex-wrap: wrap;
		padding: 0 8px 6px;
	}

	&.centered-content {
		justify-content: center;
	}

	a {
		color: $black;
		text-decoration: none;

		&::before {
			@include absolute(0, 0, 0, 0);
			content: '';
		}
	}

	&:not(:last-child) {
		border: {
			right: 0.5px solid $silverChalice;
		}
	}

	&.table-head__cell {
		padding: 0 8px 13.5px;
		min-height: 15px;
		font: {
			family: $openSans;
			size: 12px;
			weight: $semiBold;
		}
		text-transform: uppercase;

		&:first-child {
			font: {
				weight: $bold;
			}
		}

		@include breakpoint($mobile, $tablet) {
			font-size: 10px;
			padding: 0 4px 7px;
		}
	}
}
