@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.search {
	min-width: 100%;
	margin-bottom: 12px;

	input::placeholder {
		font-size: 14px;
		font-weight: $light;
		color: $silverChalice;
	}
}
