@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.companies-list {
	display: block;
	width: 100%;
}

.topbar {
	position: relative;
	width: 100%;
	margin-bottom: 24px;

	.title {
		font-weight: $semiBold;
		text-align: center;
		font-size: 14px;
	}

	.back {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.list {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	text-align: left;

	&.scroll {
		height: 300px;
		overflow-y: scroll;
	}

	&__item {
		margin: {
			bottom: 13px;
		}
		font: {
			family: $openSans;
			size: 14px;
			weight: $demiBold;
		}
		transition: color 0.35s;

		&:last-child {
			margin-bottom: 0;
		}

		.btn-item {
			border: 0;
			background-color: transparent;
			cursor: pointer;
			word-break: break-word;
			text-align: left;
			color: $downy;
			font-weight: $demiBold;

			&.active {
				cursor: default;
				color: $black;
				font-weight: $normal;
			}
		}
	}

	.note {
		font-size: 12px;
		font-style: italic;
		text-align: center;
		margin-bottom: 24px;

		&.empty {
			margin-bottom: 0;
		}
	}
}

.back {
	&::before {
		@include size(11px);
		border: {
			color: $downy;
		}
	}
}
