@import '../../../../styles/mixins';

.step-section-wrapper {
	margin-bottom: 50px;
}

.step-section {
	background: $calmSand;
	padding: 32px 70px;
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	border-bottom: 1px solid $comet;
	border-bottom: 4px solid $wildSandDarker;

	@include breakpoint($mobile, $tablet) {
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		flex-direction: column;
	}

	.step-name {
		min-width: 228px;
		font-size: 27px;
		font-weight: $demiBold;
		color: $tuna;
		line-height: 1;

		@include breakpoint($mobile, $tablet) {
			margin-bottom: 16px;
		}
	}

	.step-numbers {
		font-weight: $semiBold;
		font-size: 14px;
		color: $tuna;
		line-height: 1;

		@include breakpoint($mobile, $tablet) {
			margin-bottom: 16px;
		}
	}

	.actions {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		.btn + .btn {
			margin-left: 16px;
		}

		.btn {
			&.disabled {
				background-color: $silverLight !important;
				border-color: $silver !important;
				color: $white !important;
			}
		}
	}
}

.progress-bar {
	background: $wedgewood;
	height: 4px;
	transition: all 200ms linear;
	position: relative;
	top: -4px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}
