@import '../../../../../styles/variables';

.seniority-item {
	float: left;
	width: calc(50% - 7px);
}

.cover {
	margin-bottom: 80px !important;
}
