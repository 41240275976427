@import '../../../../styles/variables';

.wrap {
	&:not(:only-child):not(:last-child) {
		margin: {
			bottom: 30px;
		}
		padding: {
			bottom: 30px;
		}
		border: {
			bottom: {
				width: 1px;
				style: solid;
				color: $silver;
			}
		}
	}
}
