@import '../../../../styles/mixins';

.section-header {
	margin-bottom: 10px !important;
}

.communications {
	margin-bottom: 60px;

	.switcher {
		margin-bottom: 16px;

		.toggle {
			margin-left: 16px !important;
		}

		label {
			font-size: 16px;
			font-weight: $normal;
		}
	}

	.description {
		font-size: 14px;
		color: $mistGray;
		font-family: $openSans;
	}
}
