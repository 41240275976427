@import '../../../styles/variables';
@import '../../../styles/mixins';

.pagination {
	text-align: center;
	margin: {
		top: 24px;
		bottom: 24px;
		right: auto;
		left: auto;
	};
}
