@import '../../../../../styles/mixins';

.wrap {
	display: flex;
}

.range-info {
	display: flex;
	align-items: center;

	&__label {
		display: inline-block;
		margin: {
			left: 8px;
		}
	}
}

.label {
	margin: {
		bottom: 11px;
	}

	@include breakpoint($mobile, $desktop) {
		font: {
			size: 12px !important;
		}
	}
}

.labels {
	display: flex;
	flex-direction: column;
	margin: {
		right: 24px;
	}

	@include breakpoint($mobile, $desktop) {
		display: none;
	}
}

.labels-wrap {
	@include absolute(auto, 0, 0, 0);
}

.top {
	display: flex;
	justify-content: space-between;
	margin: {
		bottom: 18px;
	}

	@include breakpoint($mobile, $desktop) {
		margin: {
			bottom: 10px;
		}
	}
}

.body {
	position: relative;
	flex-grow: 1;
}

.value-label {
	font: {
		family: $roboto;
		size: 10px;
		weight: $normal;
	}
	color: $silverChalice;
	transform: translateX(-100%);

	@include breakpoint($mobile, $desktop) {
		margin: {
			bottom: 12px !important;
		}
		font: {
			size: 12px !important;
		}
	}
}
