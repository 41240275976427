@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.section-wrap {
	padding: 32px 46px;
	margin: 40px 0;
	background: $white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;

	.title {
		color: $tuna;
	}
}

.balance-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;

	@include breakpoint($mobile, $tablet) {
		flex-direction: column;
		align-items: flex-start;
	}

	.balance {
		font-size: 18px;
		color: $tuna;
		font-family: $openSans;

		@include breakpoint($mobile, $tablet) {
			margin-bottom: 16px;
		}

		&:before {
			content: '';
			margin-right: 16px;
			width: 18px;
			height: 29px;
			background: url('../../../../assets/icons/spark-boosted.svg');
			background-size: cover;
			background-size: contain;
			background-repeat: no-repeat;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.select {
	margin-bottom: 32px;

	.pretext {
		font-size: 14px;
		margin-right: 16px;
		display: inline-block;
	}

	.input {
		z-index: 1;

		select {
			font-weight: $light;
		}
	}
}

.empty {
	font-family: $roboto;
	font-size: 16px;
	font-weight: $normal;
}
