@import '../../../../styles/mixins';

.input {
	border: 0;
	padding: 10px 0;

	font: {
		family: $roboto;
		weight: $normal;
		size: 14px;
	}
	letter-spacing: 0.5px;
	color: $black;
	outline: none;

	@include autofill($black);

	&.disabled.visible-disable-styles {
		@extend %visible-disable-styles;
	}

	&.disabled:not(.visible-disable-styles) {
		//-webkit-text-fill-color: $black;
	}
}
