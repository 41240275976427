@import '../../../../styles/mixins';

.form {
	font: {
		size: 0;
	}
}

.item {
	@include size(175px);
	overflow: hidden;

	.tile {
		padding: 5px;

		img {
			object-fit: scale-down;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
		}
	}
}

.error {
	color: $bloodyRedApricot;
	font-size: 12px;
	margin-bottom: 12px;
    max-width: 200px;
}
