@import '../../../../styles/variables';

.tooltip {
	cursor: auto;
	display: none;
	position: absolute;
	top: 100%;
	left: 50%;
    transform: translate(-50%, 0);
	background: $white;
	width: max-content;
	padding: 16px;
	border-radius: 8px;
	color: $tunaDark;
	font-family: $roboto;
	font-weight: $normal;
	font-size: 14px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	z-index: -1;
	animation: fadeIn 0.25s ease-in-out;
}
