@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.custom-wrap {
    margin: 32px auto 24px;
    text-align: center;
    max-width: 530px;

    @include breakpoint($mobile, $tablet) {
        max-width: 100%;
    }

    .tile {
        margin: 24px auto;
        width: 216px;
    }
}