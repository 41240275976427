@import '../../../../styles/variables';

.info-value {
	font: {
		family: $openSans;
		size: 21px;
		weight: $semiBold;
	}
	color: $wedgewood;
}
