@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.wrapper {
    .item {
		padding: {
			bottom: 6px;
		}
		font: {
			family: $openSans;
			size: 16px;
			weight: $normal;
		}
		color: $tuna;

		strong {
			font: {
				weight: $semiBold;
			}
		}
	}
}
