@import '../../../../styles/mixins';

$iconIndent: 30px;

%labelUp {
	@include absolute(-13px, auto, auto, 0);
	font: {
		size: 10px;
		weight: $demiBold;
	}
	color: $dustyGray;
	text-transform: uppercase;
}

.form-item {
	$formItem: &;
	border: {
		bottom: 1px solid rgba($black, 0.6) !important;
	}

	&.error__wrap {
		@extend %errorWrapper;
	}

	&.disabled.visible-disable-styles {
		@extend %visible-disable-styles;
	}

	box-sizing: border-box;

	.label {
		$label: &;
		@include absolute(10px);

		@at-root {
			#{$formItem}:not(.first-render) .label {
				transition: all 0.3s;
			}
		}
	}

	&.has-icon {
		.label {
			@include absolute(12px, auto, auto, $iconIndent);
			font: {
				size: 12px;
			}
		}

		&-before {
			padding: {
				left: $iconIndent;
			}
		}

		&-after {
			padding: {
				right: $iconIndent;
			}
		}
	}

	.icon {
		&::before {
			font: {
				size: 16px;
			}
		}

		&.before {
			@include absolute(auto, auto, 9px, 2px);
		}
	}

	&.up-label {
		.label {
			@extend %labelUp;

			@at-root {
				#{$formItem}:not(.first-render) .label {
					transition: all 0.3s;
				}
			}
		}

		&.has-icon {
			.label {
				@include absolute(-13px, auto, auto, $iconIndent);
			}
		}
	}

	input:-webkit-autofill + label {
		@extend %labelUp;
	}

	.label-has-icon {
		&-before {
			left: $iconIndent;
		}
	}

	.error {
		@extend %errorPosition;
	}

	.spinner {
		position: absolute;
		top: 0;
		right: 0;
	}
}
