@import '../../../styles/mixins';

.infinite-scroll {
	display: flex;
	justify-content: center;
	min-height: 100px;
}

.loader {
	@include absolute(50%, auto, auto, 50%);
	transform: translateX(-50%) translateY(-50%);
}
