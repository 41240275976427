.wrap {
	display: flex;
	align-items: center;
	min-height: 179px;

	.logo {
		max-width: 160px;
		margin: {
			left: auto;
		}
	}
}
