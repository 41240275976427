@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.content {
	a {
		font: {
			weight: $semiBold;
		}
		color: $downy;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.resume-link {
	@extend %link;
	display: inline;
	cursor: pointer;
}
