@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.company-info-top {
	padding-bottom: 55px;
	padding-top: 16px;
}

.container {
	align-items: center;

	@include breakpoint($mobile, $tablet) {
		flex-wrap: wrap;
	}
}

.boosted {
	background-color: $downyLight;
}

.boost-label {
	font-family: $openSans;
	font-weight: $demiBold;
	font-size: 12px;
	letter-spacing: 3px;
	text-transform: uppercase;
	margin-bottom: 8px;

	&:before {
		content: '';
		height: 18px;
		width: 13px;
		margin-right: 8px;
		display: inline-block;
		vertical-align: middle;
	}

	&.boosting {
		color: $pastelGreen;

		&:before {
			background: url('../../../../assets/icons/spark-accent.svg');
			background-size: cover;
		}
	}

	&.not-boosting {
		color: $silver;

		&:before {
			background: url('../../../../assets/icons/spark-inactive.svg');
			background-size: cover;
		}
	}
}

.popover-width {
	top: 40px !important;
}

.info-content {
	.title {
		font-size: 14px;
		font-weight: $demiBold;
		margin-bottom: 8px;
	}

	.text {
		line-height: 20px;
	}
}

.info-box {
	margin-right: 16px;
}

.titles {
	margin: {
		right: 6%;
	}
}

.item {
	&:first-child,
	&:last-child {
		min-width: 110px;

		@include breakpoint($mobile, $tablet) {
			min-width: 80px;
		}
	}
}

.job-title {
	color: $tunaDark;
}

.subtitle {
	color: $comet;
	font-family: $roboto;

	&.type-label {
		margin-top: 3px;
	}
}

.center {
	margin: {
		right: 6%;
		top: 32px;
		bottom: 0;
	}
}

.right {
	display: flex;
	flex-direction: column;
	margin: {
		left: auto;
	}

	@include breakpoint($mobile, $tablet) {
		margin-top: 24px;
		margin-bottom: 16px;
		margin-left: 0;
	}

	.actions {
		display: flex;
		align-items: center;
	}

	.boost-progress {
		margin-top: 24px;
		display: inline-flex;
		justify-content: flex-end;
	}
}

.btn {
	line-height: 1;

	&:not(:last-child) {
		margin: {
			right: 16px;
		}
	}

	&.boost {
		border-radius: 8px;
		min-height: 36px !important;
		height: 36px;
		margin-bottom: 2px;

		&:before {
			content: '';
			background: url('../../../../assets/icons/spark-white.svg');
			height: 20px;
			width: 14px;
			margin-right: 8px;
		}

		&:hover:before {
			background: url('../../../../assets/icons/spark-accent.svg');
		}

		&.disabled {
			border-color: $silverLight;
			background-color: $silverLight;
			pointer-events: none;
		}
	}

	.promote {
		&.disabled {
			pointer-events: none;
		}
	}

	.edit {
		margin-top: -4px;
	}

	&.item-with-tooltip:hover {
		position: relative;
		cursor: pointer;

		.item-tooltip {
			display: block;
			z-index: 10;
		}
	}
}

.job-timestamp {
	font-size: 12px;
	font-weight: $normal;
	color: $comet;
}

.loader-wrap {
	position: relative;
	width: 30px;
	height: 30px;

	.loader {
		position: absolute;
		top: 4px;
		left: 4px;
	}
}
