@import '../../../../styles/mixins';

.tile {
	width: 19%;
	min-height: 160px;
	margin: 0 4px 8px;
	border: 3px solid transparent;
	cursor: pointer;

	@include breakpoint($mobile, $desktop) {
		width: 216px;
	}

	&.active {
		border-color: $downy;
		box-shadow: none;
	}

	.title {
		font-family: $openSans;
		color: $wedgewood;
	}

	.desc {
		margin-top: 8px;
		line-height: 1.3;
	}
}
