@import '../../../../styles/mixins';

.button {
	@extend %commonButtonStyles;

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}
