@import '../../../../styles/mixins';

.login-form {
	display: flex;
	transition: all 0.5s;
	width: 358px;
	min-height: 250px;

	.step {
		padding: 5px 24px;
		min-height: 240px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: all 0.5s;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: {
			top: auto;
		}
	}

	.btn:not(:last-child) {
		margin: {
			right: 11px;
		}
	}

	.input {
		width: 100%;
		margin: {
			bottom: 28px;
		}
		border: {
			color: rgba($white, 0.55) !important;
		}

		label {
			font: {
				weight: $normal;
			}
		}

		input {
			@include autofill($white);
			color: $white;
		}
	}

	.checkbox {
		@extend %signInFlowCheckboxStyle;

		color: $white;
	}

	.trouble-sign-in-wrap {
		margin: {
			top: 30px;
		}
	}
}
