@import '../../../../styles/variables';

.section-title {
	font: {
		family: $roboto;
		size: 17px;
		weight: $normal;
	}
	letter-spacing: 0.45px;
	color: rgba($tuna, 0.55);
	padding-bottom: 12px;
	margin-bottom: 16px;
	border-bottom: 0.5px solid $black;
}
