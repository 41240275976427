@import '../../../styles/variables';
@import '../../../styles/mixins';

.speech-bubble {
	background-color: $blueChillDark;
	color: $white;
	position: relative;
	font-family: $openSans;
	transition: opacity 0.5s ease-in-out;
	visibility: hidden;
	opacity: 0;
	text-align: left !important;

	&.show {
		visibility: visible;
		opacity: 1;
	}

	&:before {
		position: absolute;
		bottom: 0;
		left: -18px;
		content: '';
		background-image: url('../../../assets/icons/speech-bubble-tail-accent.svg');
		background-size: cover;
		height: 40px;
		width: 42px;
	}

	&.with-info-icon {
		&:after {
			position: absolute;
			top: 10px;
			right: 10px;
			content: '';
			background-image: url('../../../assets/icons/info.svg');
			background-size: cover;
			height: 15px;
			width: 15px;
		}
	}

	&.small {
		font-size: 16px;
		border-radius: 16px;
		padding: 24px 32px;

		&:before {
			left: -22px;
			height: 42px;
			width: 40px;
		}

		@include breakpoint($mobile, $desktop) {
			font-size: 14px;
			padding: 20px;
		}
	}

	&.medium {
		border-radius: 24px;
		padding: 24px 32px;
		font-size: 24px;

		&:before {
			left: -32px;
			height: 62px;
			width: 64px;
		}

		@include breakpoint($mobile, $desktop) {
			font-size: 16px;
			border-radius: 16px;
			padding: 24px;

			&:before {
				left: -22px;
				height: 42px;
				width: 40px;
			}
		}
	}

	&.large {
		border-radius: 46px;
		padding: 46px;
		font-size: 30px;

		&:before {
			left: -50px;
			height: 92px;
			width: 83px;
		}

		@include breakpoint($mobile, $desktop) {
			font-size: 16px;
			border-radius: 16px;
			padding: 24px;

			&:before {
				left: -22px;
				height: 42px;
				width: 40px;
			}
		}
	}

	&.right {
		&.large {
			&:before {
				bottom: 0;
				left: unset;
				right: calc(-5% + 4px);
				transform: scale(-1, 1);

				@include breakpoint($mobile, $desktop) {
					right: -22px;
				}
			}
		}
	}

	&.dark {
		background-color: $tuna;

		&:before {
			background-image: url('../../../assets/icons/speech-bubble-tail-dark.svg');
		}
	}
}
