@import '../../../../styles/variables';

.textarea {
	border: 0.5px solid $mineShaft;
	width: 100%;
	min-height: 86px;
	padding: 10px;
	resize: none;

	&,
	&::placeholder {
		font: {
			family: $openSans;
			size: 14px;
			weight: $normal;
		}
	}
	letter-spacing: 0.5px;
}
