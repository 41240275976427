@import '../../styles/mixins';

.jobs {
	position: relative;

	.empty-state-title {
		font-weight: $demiBold;
	}

	.empty-state-title,
	.empty-state-description {
		color: $comet;
	}

	.loader {
		display: flex;
		justify-content: center;
		margin-top: 20%;
	}

	.top-bar {
		padding: {
			left: 0;
			right: 0;
		}
	}

	.body {
		min-height: 77vh;

		&.loader {
			display: flex;
			justify-content: center;
		}

		.boosted {
			background-color: $downyLight;
		}
	}

	.actions {
		@extend %table-actions;

		> *:not(:last-child) {
			margin: {
				right: 36px !important;
			}

			@include breakpoint($mobile, $tablet) {
				margin: {
					right: 8px !important;
				}
			}

			@include breakpoint($tablet, $desktop) {
				margin: {
					right: 16px !important;
				}
			}
		}

		.boost {
			margin-top: 4px;

			&.disabled {
				pointer-events: none;
				cursor: auto;
			}
		}
	}

	input {
		@include autofill($black);
	}

	.remove-loader {
		margin: auto;
	}

	.item-with-tooltip:hover {
		position: relative;
		cursor: pointer;

		.item-tooltip {
			text-align: left;
			display: block;
			z-index: 10;
			line-height: 1.4;
			width: max-content;
			max-width: 325px;

			&.left-sided {
				transform: translate(-78%, 0);
			}
		}
	}
}

.error-popup-content {
	display: flex;
	align-items: center;
	justify-content: center;
}
