@import '../../../../styles/variables';

.item {
	font: {
		size: 14px;
		weight: $normal;
	}
	color: $wedgewood;
	cursor: pointer;
}
