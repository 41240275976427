@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.analytics {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 37px;
	max-width: 100%;

	@include breakpoint($mobile, $desktop) {
		flex-direction: column;
		align-items: stretch;
	}

	@include breakpoint($mobile, $tablet) {
		flex-direction: column;
	}

	.section {
		background: $white;
		border: 1px solid $silver;
		border-radius: 8px;

		.subtitle {
			margin: {
				top: 4px;
				bottom: 28px;
			}
		}

		&.applications-chart {
			padding: 32px 50px;
			width: calc(100% - 383px - 64px);
			min-height: 342px;
			margin-right: 64px;

			@include breakpoint($mobile, $desktop) {
				width: 100%;
				margin-bottom: 32px;
				padding: 16px 24px 16px;
			}

			@include breakpoint($mobile, $tablet) {
				width: 100%;
				position: relative;
			}

			&.with-empty-message {
				position: relative;
			}

			.top-section {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.legend {
					color: $silverChalice;
					font-size: 10px;
					margin-right: 5%;
					margin-top: 16px;

					&:before {
						content: '';
						display: inline-block;
						vertical-align: middle;
						width: 15px;
						height: 15px;
						margin-right: 8px;
						background: rgba($downy, 0.3);
						border: 1px solid $blackHaze;
					}
				}
			}

			.empty-chart-message {
				position: absolute;
				width: 300px;
				top: calc(50% - 10px);
				left: calc(50% - 150px);
				font-family: $openSans;
				font-size: 18px;
			}
		}
	}

	.boost-job {
		&.inactive {
			pointer-events: none;
			opacity: 0.3;
		}
	}
}

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
}
