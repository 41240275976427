@import '../../../../styles/variables';

.wrapper {
	width: 100%;
	
	.score {
		display: inline-flex;
		align-items: flex-start;
		width: 100%;
		height: 8px;
		border-radius: 12px;
		box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
		background: linear-gradient(110deg, $turquoiseLight 0%, $cyanAzureDark 100%);
		background-blend-mode: soft-light, normal;
		box-sizing: border-box;
		position: relative;

		.progress {
			position: absolute;
			left: 0;
			bottom: 0;
			display: inline-block;
			width: 8px;
			height: 18px;
			background: url('../../../../assets/icons/progress-gear.svg');
			background-size: cover;
			background-repeat: no-repeat;
			transition: all 0.6s ease-in-out;
		}
	}
}
