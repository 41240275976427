@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.radio {
	display: none;

	@at-root &__wrap {
		position: relative;
		display: block;

		.label {
			font: {
				weight: $normal;
				size: 16px;
				family: $openSans;
			}
			color: $black;
			line-height: 1;
			.text {
				margin: {
					left: 6px;
				}
			}
		}
	}

	@at-root .label {
		display: inline-flex;
		align-items: center;
		cursor: pointer;

		&::before {
			@include size(14px);
			min-width: 14px;
			content: '';
			display: inline-flex;
			margin: {
				bottom: auto;
			}
			border: {
				style: solid;
				width: 1px;
				color: $silver;
				radius: 50%;
			}
		}
	}

	&:checked {
		color: $downy;

		@at-root & + label {
			&::after {
				@include absolute(4px, auto, auto, 4px);
				@include size(8px);
				content: '';
				display: inline-flex;
				border: {
					radius: 50%;
				}
				background: {
					color: $downy;
				}
			}

			&::before {
				border: {
					color: $downy;
				}
			}
		}
	}
}
