@import '../../../../styles/variables';

.hidden {
	display: none;
}

.toolbar__item {
	border: {
		right: 1px solid rgba($black, 0.3);
	}
}
