@import '../../../../styles/variables';

.chip {
	border: 1px solid $wedgewood;
	border-radius: 64px;
	padding: 6px 16px;
	display: flex;
	flex-direction: row;
    flex-wrap: nowrap;
	align-items: center;
	margin: 4px 8px;

	.text {
		margin-right: 16px;
		font: {
			family: $openSans;
			size: 14px;
			weight: $normal;
		}
		color: $tunaDarkBlack;
	}



	.remove {
		width: 12px !important;
		height: 12px !important;
		cursor: pointer;

		&:before, &:after {
			background-color: $downy;
			height: 12px;
		}
	}
}
