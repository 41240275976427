@import '../../../styles/mixins';

.specific-fit-score {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.text {
		font: {
			family: $openSans;
			size: 10px;
			weight: $demiBold;
		}
		color: $comet;
		text-align: center;
		margin-top: 6px;
	}
}
