@import '../../../styles/mixins';

.wrap {
	@extend %downloadItemElements;
	text-align: center;

	.loader {
		margin: 30px;
	}
}
