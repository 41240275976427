@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.tabs-wrap {
	text-align: center;
}

.tab {
	font-size: 16px;
	padding-bottom: 9px;

	@include breakpoint($mobile, $tablet) {
		font-size: 12px;
	}

	&.active-tab {
		border-bottom-color: $tuna !important;
	}
}