@import '../../../../../styles/variables';

.form-section {
	margin-bottom: 32px !important;
}

.with-error {
	margin-bottom: 24px !important;
}

.add-facility {
	font-size: 14px;
	line-height: 21px;
	margin: 8px 0 24px;

	& > button {
		font-size: 14px;
		line-height: 21px;
	}
}

.salary-wrap {
	> *:not(:first-child) {
		margin: {
			left: 20px;
		}
	}

	.with-error {
		margin-bottom: 40px !important;
	}
}

.seniority-item {
	float: left;
	width: calc(50% - 7px);

	&:last-child {
		margin: {
			left: 14px;
		}
	}
}

.checkbox {
	label {
		font-size: 14px !important;
		line-height: 20px !important;
		color: $tuna !important;
	}
}

.department {
	margin-bottom: 90px !important;
}

.error {
	margin-top: 8px;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
}

.loader {
	display: flex;
	margin-top: 5%;
}
