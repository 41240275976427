@import '../../../../styles/mixins';

.form-item {
	&.disabled {
		&.visible-disable-styles {
			.select {
				border: {
					color: $silver;
				}
				color: $silver;
			}

			.icon::before {
				color: $silver;
			}
		}

		&:not(.visible-disable-styles) {
			.select {
				-webkit-text-fill-color: $black;
				opacity: 1;
			}
		}
	}

	.icon {
		@include absolute(55%, 12px, auto, auto);
		transform: translateY(-50%);
		z-index: -1;
		&::before {
			font: {
				size: 10px;
			}
			color: $black;
		}
	}
}

.loader {
	@include absolute(50%, auto, auto, 5px);
	transform: translateY(-50%);
}

.select {
	display: block;
	border-radius: 0;
	border: 1px solid rgba($tuna, 0.5);
	padding: 9px 28px 9px 10px;
	appearance: none;
	background: transparent;
	font: {
		family: $openSans;
		weight: $semiBold;
		size: 14px;
	}
	color: $tuna;
	outline: {
		color: $downy;
	}
}
