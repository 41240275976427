@import '../../styles/mixins';

.signup {
	.sparke {
		position: fixed;
		bottom: 50px;
		right: 50px;

		@include breakpoint($mobile, $tablet) {
			position: relative;
			bottom: 0;
			right: 0;
			margin: 32px;
		}
	}
}
