@import '../../../../styles/mixins';

.button {
	@extend %buttonAsLink;

	&[disabled] {
		pointer-events: none;
		opacity: 0.5;
	}
}
