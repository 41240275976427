@import '../../../../styles/variables';

.icon {
	font-size: 0;
	color: $downy;

	&::before {
		font: {
			size: 21px;
		}
	}

	&.large {
		&::before {
			font: {
				size: 37px;
			}
		}
	}

	&.disabled {
		color: $silver;
	}
}
