@import '../../../styles/variables';

.badge {
	position: relative;
	margin-right: 8px;
	display: inline-block;
	position: relative;
	line-height: 1;
	vertical-align: middle;

	.alt {
		font-size: 16px;
		font-weight: $semiBold;
		line-height: 20px;
	}
}
