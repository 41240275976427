@import '../../styles/mixins';

.header {
	@include fixed(0, 0, auto, 0);
	display: flex;

	@include breakpoint($mobile, $tablet) {
		padding-left: 30px !important;
		padding-right: 30px !important;
		height: 100px;
		align-items: flex-start;
	}

	.header-wrapper {
		max-width: 1366px;
		margin: 0 auto;
		width: 100%;
		display: flex;
		align-items: flex-end;
	}

	.close {
		@include absolute(50%, 85px, auto, auto);
		transform: translateY(-50%);

		@include breakpoint($mobile, $tablet) {
			right: 30px;
		}

		&::after,
		&::before {
			background: {
				color: $downy;
			}
		}

		&:hover {
			&::after,
			&::before {
				background: {
					color: $white;
				}
			}
		}
	}
}

.status-label {
	font: {
		family: $openSans;
	}
}

.candidate-nav {
	margin: {
		top: auto;
		right: 14%;
		bottom: auto;
		left: auto;
	}

	@include breakpoint($mobile, $tablet) {
		margin-right: 5%;
	}
}

.loader {
	@include absolute(50%, auto, auto, 35%);
}

.add-field {
	position: relative;
	z-index: 2;
}

.job-info {
	max-width: 25%;

	@include breakpoint($mobile, $tablet) {
		margin: 10px 0 0;
		max-width: 50%;
	}

	@include breakpoint($extraDesktop) {
		max-width: 30%;
	}
}

.tabs {
	position: static;

	.tabs-wrap {
		@include fixed(7px, auto, auto, 50%);
		z-index: 1;
		display: inline-flex;
		transform: translateX(-50%);

		@include breakpoint($mobile, $tablet) {
			top: 50px;

			& > * {
				font-size: 12px !important;
			}
		}

		* {
			font: {
				size: 16px;
			}
			color: $white;
		}

		.tab {
			padding: 20px 15px;

			@include breakpoint($mobile, $tablet) {
				padding: 15px;
			}
		}
	}
}

.cannabis-work-experience-label {
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;

	@include breakpoint($desktop) {
		min-width: 152px;
	}

	&__content {
		position: relative;
		margin: {
			left: auto;
		}
		padding: {
			left: 30px;
		}
	}

	.label-icon {
		@include absolute(-4px, auto, auto, 0);
	}

	.overall {
		top: -10px;
	}
}

.sidebar {
	@include absolute(74px, 0, unset, auto);
	padding: 48px 65px 59px !important;
	overflow-x: hidden;
	background: {
		color: rgba($wildSand, 0.5);
	}

	@include breakpoint($desktop, $extraDesktop) {
		padding: 63px 30px !important;
	}

	@include breakpoint($mobile, $desktop) {
		position: relative;
	}

	.title {
		color: $comet;
		letter-spacing: 0.6px;
	}

	.desc {
		margin: {
			bottom: 30px;
		}
		font: {
			size: 18px;
			weight: $normal;
		}
		line-height: 1.38;
		letter-spacing: 0.45px;
		color: $comet;
	}

	.commute {
		margin-bottom: 22px;
		white-space: nowrap;
		text-align: center;

		.title {
			font-size: 16px;
			font-weight: $demiBold;
			color: $comet;
			margin-bottom: 16px;
			line-height: 1;
		}
	}

	&__title {
		text-align: center;
		margin: {
			top: 0;
		}
		font: {
			family: $roboto;
			size: 16px;
			weight: $bold;
		}
		color: $comet;
		letter-spacing: 0.45px;
	}

	.score-wrap {
		display: flex;
		justify-content: center;
	}

	.map-with-distance {
		@include breakpoint($mobile, $desktop) {
			margin: 0 auto;
		}
	}

	.info-items {
		.info-item {
			display: flex;
			align-items: center;
			min-height: 54px;
			padding: 10px 0;
			border: {
				bottom: {
					style: solid;
					width: 1px;
					color: $silver;
				}
			}

			&:not(:first-child) {
				word-break: break-word;
			}

			.item {
				font: {
					size: 14px;
					family: $openSans;
				}
			}

			.download {
				.download-icon:before {
					color: $downy !important;
				}

				&:hover {
					.download-icon:before {
						color: $comet !important;
					}
				}

				&.disabled {
					pointer-events: none;

					&:hover {
						.download-icon:before {
							color: $downy !important;
						}
					}
				}
			}

			.linkedin {
				i::before {
					color: $blueChill;
					font: {
						size: 28px;
					}
				}

				&:not(.disabled):hover {
					i::before {
						color: $comet;
					}
				}

				&.disabled {
					pointer-events: none;
				}
			}
		}
	}
}

.content {
	padding: {
		top: 117px !important;
		right: 33% !important;
		bottom: 20px;
	}

	.loader {
		@include absolute(50%, auto, auto, 35%);
	}

	.top {
		display: flex;
		flex-direction: column;
		padding: 0 0 23px;

		&.with-border {
			border: {
				bottom: {
					width: 1px;
					style: solid;
					color: $silver;
				}
			}
		}

		&__header {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin: {
				bottom: 14px;
			}
		}

		&__footer {
			display: flex;
			align-items: center;

			@include breakpoint($mobile, $tablet) {
				align-items: flex-start;
				flex-direction: column;
			}
		}

		.status {
			display: flex;
			align-items: center;
			margin: {
				left: auto;
			}

			&-label {
				margin: {
					right: 7px;
				}
			}

			select {
				border: {
					color: $tuna;
				}

				& {
					color: $tuna;
				}
			}

			i::before {
				color: $tuna;
			}
		}
	}

	.personality {
		margin: {
			bottom: 32px;
		}
		border: {
			bottom: {
				width: 1px;
				style: solid;
				color: $silver;
			}
		}
		line-height: 1.4;

		&__text {
			display: block;
			max-width: 77%;
			padding: 0 0 25px;
			font: {
				family: $roboto;
				size: 26px;
			}
			color: $tuna;
			letter-spacing: 0.5px;
		}
	}


	.item {
		padding: {
			bottom: 6px;
		}
		font: {
			family: $openSans;
			size: 16px;
			weight: $normal;
		}
		color: $tuna;

		strong {
			font: {
				weight: $semiBold;
			}
		}
	}
}

.layout {
	min-height: 100vh;
	position: relative;
	max-width: 1366px;
	margin: 0 auto;

	@include breakpoint($mobile, $desktop) {
		display: block !important;
	}
}

.applied-date {
	display: block;
	margin: {
		bottom: 5px;
	}
	font: {
		family: $openSans;
		size: 16px;
		weight: $normal;
	}
	color: $tuna;
	text-align: right;
}

.note-content {
	position: relative;
	z-index: 0;
	width: 72%;
	margin-top: 20px;

	@include breakpoint($mobile, $tablet) {
		width: 100%;
	}

	.title {
		position: relative;
		z-index: 7;
		margin: {
			bottom: 33px;
		}
	}

	.name-heading {
		margin-bottom: 34px;
	}
}

.candidate-location-info {
	padding: 26px 0 !important;

	&__item {
		&:not(:last-child) {
			margin: {
				bottom: 8px;
			}
		}
	}
}

.answer-section {
	padding: 23px 0;
	border: {
		bottom: {
			width: 1px;
			style: solid;
			color: $silver;
		}
	}
	max-width: 73%;

	@include breakpoint($mobile, $tablet) {
		max-width: 100%;
	}

	&__title {
		font: {
			family: $roboto;
		}
		color: $comet;
		letter-spacing: 0.45px;
	}

	&__text {
		white-space: pre-wrap;
	}
}
