@import '../../../../styles/mixins';

$checkboxSize: 20;

.checkbox {
	line-height: 0.8;

	.icon {
		@include absolute(11px, auto, auto, 10px);
		@include size($checkboxSize, $checkboxSize + 1);
		display: none;
		align-items: center;
		justify-content: center;
		margin: 0;
		transform: translateX(-50%) translateY(-50%);
	}

	&.disabled {
		@extend %disabled-field;
	}

	.input {
		@include absolute(11px, auto, auto, 10px);
		@include size(0);
		opacity: 0;

		&:focus + .label {
			&::before {
				@include absolute(-2px, auto, auto, -2px);
				@include size(#{$checkboxSize + 4}px);
				content: '';
				display: inline-block;
				outline: 1px solid $downy;
			}
		}

		&:checked + .label .icon {
			display: inline-flex;

			&::before {
				font: {
					size: 10px;
				}

				color: $mineShaft;
			}
		}

		&.white-theme {
			&:checked + .label .icon {
				&::before {
					color: $white;
				}
			}
		}
	}

	.label {
		position: relative;
		display: inline-block;
		color: $white;
		min-height: #{$checkboxSize}px;
		padding: {
			left: 20px;
		}
		letter-spacing: 0.5px;
		line-height: 17px;
		text-align: left;
		font: {
			size: 12px;
			weight: $normal;
		}
		cursor: pointer;

		&.has-text {
			padding: {
				left: 30px;
			}
		}

		.icon {
			&::before {
				margin: {
					left: 3px;
				}
			}
		}

		.text {
			position: relative;
			top: 2px;
		}

		a {
			text-decoration: none;
			color: $downy;
			font: {
				weight: $bold;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		&::after {
			@include absolute();
			@include size(20px);
			content: '';
			display: inline-flex;
			border: 2px solid $tuna;
			border: {
				radius: 4px;
			}
			box-sizing: border-box;
		}

		&.white-theme {
			&::after {
				border: 2px solid $white;
			}
		}
	}
}
