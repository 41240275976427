@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.content {
	padding: 0;
}

.form-section {
	margin-bottom: 32px !important;
}

.with-error {
	margin-bottom: 24px !important;
}

.add-facility {
	font-size: 14px;
	line-height: 21px;
	margin: {
		bottom: 30px;
	}

	& > button {
		font-size: 14px;
		line-height: 21px;
	}
}

.salary-wrap {
	> *:not(:first-child) {
		margin: {
			left: 20px;
		}
	}
}

.seniority-item {
	float: left;
	width: calc(50% - 7px);

	&:last-child {
		margin: {
			left: 14px;
		}
	}
}

.edit-mode-status {
	display: flex;

	@at-root &__wrap {
		display: flex;
		padding: 32px 0;
		border: {
			top: 1px solid $black;
			bottom: 1px solid $black;
		}

		> fieldset {
			margin: {
				bottom: 0 !important;
			}
		}
	}

	> *:not(:last-child) {
		margin: {
			right: 35px;
		}
	}
}

.checkbox {
	margin-top: 26px;

	label {
		font-size: 14px !important;
		line-height: 20px !important;
		color: $tuna !important;
	}
}

.loader {
	display: flex;
	margin-top: 5%;
}

.sparke {
	position: fixed !important;
	bottom: 50px;
	right: 50px;

	@include breakpoint($mobile, $tablet) {
		position: relative !important;
		bottom: 0;
		right: 0;
		margin: 32px;
	}
}
