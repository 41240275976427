@import '../../../../styles/mixins';

.wrap {
	max-width: 80%;
	margin: {
		bottom: 36px;
	}
	font: {
		family: $openSans;
		size: 16px;
		weight: $semiBold;
	}
	color: $comet;

	a {
		@extend %link;
	}
}
