@import '../../../../styles/variables';

.number-incrementer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.number {
		font-size: 36px;
		font-weight: $normal;
		color: $wedgewood;
	}

	.increment-handles {
		margin-left: 8px;

		.handle {
			cursor: pointer;

			&.disabled {
				opacity: 0.3;
				cursor: default;
				pointer-events: none;
			}

			&.up {
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-bottom: 12px solid $downy;
				margin-bottom: 4px;

				&.disabled {
					border-bottom-color: $silver;
				}
			}

			&.down {
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 12px solid $downy;

				&.disabled {
					border-top-color: $silver;
				}
			}
		}
	}
}
