@import '../../../../styles/variables';

.wrap {
	:global(.rdw-editor-toolbar) {
		border: {
			top: 1px solid;
			left: 1px solid;
			right: 1px solid;
			color: $silver;
			radius: 5px 5px 0 0;
		}
	}

	:global(.rdw-editor-main) {
		border: {
			color: $silver;
		}
	}

	.bottom {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		min-height: 55px;
		border: {
			left: 1px solid;
			bottom: 1px solid;
			right: 1px solid;
			color: $silver;
			radius: 0 0 5px 5px;
		}
		padding: 11px 16px;

		.btn {
			margin: {
				left: auto;
			}
			font: {
				size: 16px;
			}
		}

		.loader {
			margin: {
				left: auto;
			}
		}
	}
}
