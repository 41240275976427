@import '../../../../styles/mixins';

.logo {
	font-size: 0;

	.hire {
		width: 100px;
		height: 50px;

		@include breakpoint($mobile, $tablet) {
			width: 70px;
			height: 40px;
		}
	}
}
