@import '../../../../styles/mixins';

.wrap {
	.logo {
		@include fixed(155px, auto, auto, 70px);
		max-width: 225px;
	}

	.header {
		width: 100%;
		justify-content: space-between;
		min-height: 73px;
		padding: 20px 75px;
	}

	.header-title {
		@include absolute(50%, auto, auto, 50%);
		transform: translateX(-50%) translateY(-50%);
	}

	&__content {
		width: 100%;
		height: 100%;
		padding: 0 !important;
	}

	.container {
		margin: {
			top: 68px;
			bottom: 20px;
		}
	}

	.title {
		color: $tuna;
		font: {
			size: 3.22vw !important;
			weight: $normal;
		}
		text-align: center;
	}

	.user-info {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		margin: {
			bottom: 40px;
		}

		@include breakpoint(1290px) {
			flex-direction: row;
			align-items: center;
			text-align: center;
		}

		&__item {
			position: relative;
			min-width: calc(50% - 8px);
			@include breakpoint(0, 1290px) {
				text-align: center;

				&::before {
					display: none !important;
				}
			}

			&:only-child {
				width: 100%;
			}

			&:last-child {
				&:not(:only-child) {
					@extend %slash;

					&::before {
						@include absolute(50%, auto, auto, 0);
						transform: translateY(calc(-50% + 2px));
						width: 1px;
						height: 15px;
						background-color: $black;
					}
				}
			}

			&:not(:last-child):not(:only-child) {
				margin: {
					bottom: 5px;
				}

				@include breakpoint(1290px) {
					margin: {
						bottom: 0;
					}
					padding: {
						left: 0;
						right: 16px;
					}
				}
			}

			@include breakpoint(1290px) {
				&:not(:only-child) {
					padding: {
						right: 0;
						left: 16px;
					}
				}
			}
		}

		.link {
			box-sizing: border-box;
			font: {
				family: $openSans;
				size: 16px;
				weight: $bold;
			}
			color: $tuna;
			text-decoration: none;
			text-align: center;
			word-break: break-word;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.description {
		margin: {
			bottom: 18px;
		}
		font: {
			size: 14px;
			weight: $light;
		}
	}

	.error-message {
		text-align: center;
	}

	.engin-wurk {
		display: block;
		max-width: 160px;
		margin: 50px auto 0;
	}
}
