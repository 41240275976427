@import '../../../styles/mixins';

.wrap {
	display: inline-flex;
	position: relative;

	.text {
		opacity: 0;
		position: relative;
		top: -3px;
		margin: {
			left: 5px;
		}
		font: {
			family: $openSans;
			size: 18px;
		}
		color: $downy;
		transition: opacity 100ms;

		&.top {
			position: absolute;
			top: -30px;
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;
			font-size: 16px;
		}
	}

	&.has-text {
		.text {
			opacity: 1;
		}
	}
}

.btn {
	position: relative;
	font: {
		size: 14px;
		weight: $semiBold;
	}
	color: $black;

	.input {
		@include absolute(0, 0, 0, 0);
		z-index: -1;
		opacity: 0;
	}
}
