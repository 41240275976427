@import '../../../styles/variables';

.top-page-layout {
	padding: {
		top: 28px;
		bottom: 65px;
	}
	background: {
		color: rgba($blackHaze, 0.5);
	}
}
