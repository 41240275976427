@import '../../../../styles/mixins';

.templates {
	.subject,
	.body {
		font-size: 14px;
		font-weight: $normal;
	}

	.subject {
		margin-bottom: 24px;

		.title {
			color: $mistGray;
			margin-right: 4px;
		}

		& > * {
			display: inline;
			margin: 0;
		}
	}

	.body {
		a {
			@extend %link;
			font-weight: $semiBold;
		}

		ol {
			padding-left: 24px;
		}

		ul {
			padding-left: 8px;
		}
	}

	:global(.tag) {
		color: $wedgewood;
	}

	.subject-editor {
		margin-bottom: 32px;

		.label {
			text-transform: uppercase;
			font-size: 10px;
			font-weight: $demiBold;
			color: $dustyGray;
			margin-bottom: 8px;
		}
	}

	.body-editor {
		width: 552px;

		@include breakpoint($mobile, $tablet) {
			width: 90%;
		}

		:global {
			.rdw-editor-toolbar {
				border-top: none;
			}

			.rdw-option-wrapper {
				border-color: transparent;
			}
		}
	}

	.placeholders {
		width: 552px;

		@include breakpoint($mobile, $tablet) {
			width: 90%;
		}

		.description {
			font-size: 14px;
			font-weight: $normal;
			color: $comet;
			margin-top: 24px;
		}

		.template-values {
			margin-top: 16px;

			.value-item {
				cursor: pointer;

				& + .value-item {
					&:before {
						content: '|';
						color: $tuna;
						margin: 0 10px;
						cursor: auto;
					}
				}
			}
		}

		.calendar-link {
			margin-top: 16px;
			margin-bottom: 30px;

			.link-title,
			.link-description {
				color: $comet;
				font-weight: $normal;
				font-size: 14px;
				line-height: 1.5;
			}

			.link-title {
				font-weight: $demiBold;
			}
		}
	}
}
