@import '../../../../styles/variables';

.editor-wrapper {
	border-width: 0.5px !important;

	&.as-input {
		border-color: transparent;
		border-bottom-color: $black;
		padding: 0;

		:global {
			.public-DraftStyleDefault-block {
				white-space: nowrap;
				overflow: scroll;
				margin-bottom: 6px;
				-ms-overflow-style: none; /* Internet Explorer 10+ */
				scrollbar-width: none; /* Firefox */

				&::-webkit-scrollbar {
					display: none; /* Safari and Chrome */
				}
			}
		}
	}

	.toolbar__item {
		border: {
			right: 1px solid rgba($black, 0.3);
		}
	}

	ol {
		padding-left: 8px;
	}
}

.hidden {
	display: none;
}

.form-error {
	text-align: left;
	margin-top: 6px;
}
