@import '../../styles/mixins';

.wrap {
	@include absolute(50%, auto, auto, 50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 358px;
	transform: translateX(-50%) translateY(-50%);

	> * {
		color: $black;
	}
}

.input {
	width: 100%;
	color: $black;
}

.box {
	min-height: 250px;
	padding: 20px 24px 40px;
	border: {
		color: $tuna;
	}
	input {
		-webkit-text-fill-color: $black !important;
	}
}

.actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: {
		top: auto;
	}

	*:not(:last-child) {
		margin: {
			right: 11px;
		}
	}
}
