@import '../../../styles/mixins';

.validation {
	max-width: 489px;
	margin: 32px auto;
	text-align: center;

	@include breakpoint($mobile, $tablet) {
		max-width: 85%;
	}

	.title {
		@include breakpoint($mobile, $tablet) {
			font-size: 34px !important;
		}
	}

	.desc {
		font-size: 16px;
		font-weight: $normal;
		margin-bottom: 60px;

		.link {
			font-weight: $demiBold;
		}
	}

	.form {
		margin: 0 auto;

		.inputs {
			max-width: 329px;
			margin: 0 auto 48px;
		}
	}

	.btn {
		&.disabled {
			background-color: $silverLight !important;
			border-color: $silver !important;
			color: $white !important;
		}

		@include breakpoint($mobile, $tablet) {
			max-width: 100%;
		}
	}

	.remark {
		line-height: 24px;
		margin: 32px 0;
		font-size: 14px;

		.link {
			font-size: 14px;
			font-weight: $demiBold;
			position: relative;

			&:after {
				transition: all 0.2s linear;
				position: absolute;
				content: url('../../../assets/icons/check.svg');
				height: 10px;
				color: $downy;
				margin-left: 5px;
				opacity: 0;
			}

			&.clicked {
				&:after {
					opacity: 1;
				}
			}
		}
	}
}
