@import '../../../../styles/mixins';

.wrap {
	display: flex;
	padding: {
		top: 10px;
		right: 0;
		left: 0;
		bottom: 10px;
	}

	.content {
		position: relative;
		z-index: 0;
		display: flex;
		width: 100%;
	}

	&.edit {
		position: relative;
		z-index: 3;
		background: {
			color: $alabaster;
		}

		.content {
			z-index: 3;
		}

		.edit-field {
			width: 100%;
		}
	}

	.edit-actions {
		display: flex;
		margin: {
			left: auto;
		}

		.slash {
			@extend %slash;
			margin: 0 8px;
		}
	}

	.actions {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		min-width: 90px;
		margin: {
			left: auto;
		}

		.actions-wrap {
			display: none;

			& > *:not(:last-child) {
				margin: {
					right: 24px;
				}
			}
		}
	}

	&:hover {
		.actions-wrap {
			display: block;
		}
	}

	&:not(:last-child) {
		margin: {
			bottom: 22px;
		}
	}

	.note-content {
		margin: {
			bottom: 9px;
		}
		color: $tuna;

		> *:first-child {
			br:first-child {
				display: none;
			}
		}

		&,
		* {
			font: {
				size: 16px !important;
				weight: $semiBold;
			}

			ul,
			ol {
				margin: {
					bottom: 10px !important;
				}

				li {
					margin: {
						left: 0 !important;
					}
				}
			}

			ol {
				padding: {
					left: 17px;
				}
			}

			strong {
				&,
				* {
					font: {
						weight: $bold;
					}
				}
			}

			a {
				color: $downy !important;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		* {
			&:first-child {
				margin: {
					top: 0;
				}
			}

			&:last-child {
				margin: {
					bottom: 0;
				}
			}
		}
	}

	.avatar {
		margin: {
			right: 16px;
		}
	}

	.info {
		font: {
			weight: $normal;
		}
		color: rgba($black, 0.56);
	}

	.bottom {
		display: flex;
	}

	.status-note {
		font-style: italic;
	}
}
