@import '../../../../styles/variables';

.wrap {
	text-align: center;
	color: $white;

	.desc {
		margin: {
			bottom: 40px;
		}
		font: {
			family: $roboto;
			size: 16px;
			weight: $normal;
		}
	}
}
