@import '../../styles/mixins';

.screen {
	padding: 60px 0;
}

.tabs {
	@extend %content-shifting-up;
	width: 100%;
}

.tabs-wrap {
	@include breakpoint($mobile, $tablet) {
		border-bottom: 3px solid rgba($blackHaze, 0.5);
	}
}
