@import '../../../../styles/variables';

.sort-icon {
	display: inline-flex;
	align-items: center;
	margin: {
		left: 8px;
	}
	font: {
		size: 0;
	}
	transition: transform 0.5s;

	i {
		&::before {
			font: {
				size: 12px;
			}
			color: $tuna;
		}
	}

	&.open {
		transform: rotate(-180deg);
	}
}
