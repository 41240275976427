@import '../../../styles/variables';

.back {
	display: flex;
	align-items: center;
	justify-content: center;
	background: {
		color: transparent;
	}
	border: 0;
	cursor: pointer;

	&:before {
		border: {
			width: 2px 2px 0 0;
			style: solid;
		}
		content: '';
		display: inline-block;
		height: 20px;
		width: 20px;
		transform: rotate(-135deg);
		vertical-align: top;
		color: $white;
	}
}
