@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.content {
	background: {
		color: white;
	}
	padding: {
		top: 42px;
		bottom: 42px;
	}
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
}

.form {
	display: flex;
	flex-direction: column;
	padding: {
		left: 34px;
		right: 34px;
	}

	& > .title {
		font-size: 24px;
		margin-bottom: 24px;
		color: $tuna;
		font-family: $openSans;

		&.with-popup {
			&.flex {
				display: inline-flex;
			}
		}
	}

	.note {
		font-size: 14px;
		color: $tuna;
	}

	.products-group {
		label {
			font-family: $roboto;
			color: $tunaDarkBlack;
			font-size: 14px;
		}

		.product-label {
			.highlighted {
				color: $wedgewood;
				font-weight: $demiBold;
			}
		}
	}

	.btn {
		margin: 48px auto;

		@include breakpoint($mobile, $tablet) {
			min-width: 100% !important;
		}
	}

	.info-box {
		margin-left: 12px;
		line-height: 0;

		&.inline {
			display: inline;
		}
	}

	.info-content {
		line-height: 1.4;

		.title {
			font-size: 14px;
			font-weight: $demiBold;
			margin-bottom: 8px;
		}

		.text {
			line-height: 20px;
		}

		.learn-more {
			font-size: 14px;
			margin-top: 8px;
			display: inline-block;
		}
	}

	.popover-width {
		width: 335px !important;
		min-width: 335px !important;
		left: 100px;
		top: 20px;
	}
}

.success-message,
.error-message {
	color: $tuna;
	padding: {
		left: 38px;
		right: 38px;
		bottom: 16px;
	}

	.title {
		font-size: 36px;
		margin-bottom: 24px;
		font-family: $openSans;
	}

	.description {
		font-size: 16px;
		line-height: 1.4;
	}

	.btn {
		display: block;
		margin: 48px auto 0;
		width: 272px !important;
		min-width: 272px !important;
		max-width: 272px !important;

		@include breakpoint($mobile, $tablet) {
			min-width: 100% !important;
		}
	}

	.link {
		@extend %link;
		word-break: break-all;

		&:hover {
			text-decoration: none;
		}
	}
}
