@import '../../../../styles/mixins';

.wrap {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: {
		color: $blackHaze;
	}

	.info {
		text-align: center;
	}

	.close {
		@include absolute(4px, 4px, auto, auto);
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.text {
		display: block;
		font: {
			family: $openSans;
			size: 16px;
		}
		color: $downy;
	}

	.desc {
		margin: {
			top: 8px;
		}
		font: {
			family: $openSans;
			size: 12px;
			weight: $semiBold;
		}
		color: $comet;
	}

	.loader {
		@include absolute(50%, auto, auto, 50%);
		z-index: 2;
		transform: translateX(-50%) translateY(-50%);
	}
}
