@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.company-form {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 100%;
	height: 0;
	width: 100%;
	transition: all 0.4s linear;
	z-index: -10;
	background-color: $wedgewood;
	justify-content: center;
	align-items: flex-start;
	padding: 76px;
	display: flex;
	flex-direction: column;

	@include breakpoint($mobile, $desktop) {
		padding: 76px 34px;
		justify-content: flex-start;
	}

	.sparke {
		margin-bottom: 20px;

		@include breakpoint($mobile, $desktop) {
			margin-bottom: 40px;
		}
	}

	.bubble-content {
		font-size: 16px;
		font-family: $roboto;
		margin-top: 6px;
		line-height: 1.4;
	}

	.speech-text {
		& + .speech-text {
			margin-top: 16px;
		}
	}

	.form {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 1300px;
		margin: 0 auto;

		.company-bubble {
			align-self: flex-end;
			margin-right: 60px;
			padding: 36px 56px 56px;
			position: relative;
			right: -60px;

			@include breakpoint($mobile, $desktop) {
				margin-right: 30px;
				padding: 24px;
				right: 0;
			}
		}

		.btn {
			margin: 54px auto 16px;
			position: relative;
			min-height: 56px;

			@include breakpoint($mobile, $desktop) {
				min-width: 225px !important;
			}

			&:before {
				content: '';
				background-image: var(--icon-sparkling-stars);
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				bottom: calc(50% - 13px);
				left: 25px;
				width: 26px;
				height: 23px;
			}

			&.disabled {
				opacity: 0.5;
				background-color: var(--color-form-disabled-elements) !important;
			}
		}

		.company-info {
			color: var(--color-text-on-dark-background);

			.text {
				line-height: 56px;

				@include breakpoint($mobile, $desktop) {
					line-height: 1.4;
				}

				.substring {
					font-size: 24px;
					margin-right: 8px;

					@include breakpoint($mobile, $desktop) {
						font-size: 16px;
					}
				}

				.textarea {
					display: block;
					border: none !important;
					background: none !important;
					border-bottom: 1px solid $white !important;
					outline: none !important;
					font-size: 24px !important;
					color: $white !important;
					min-width: 800px;
					resize: none;
					overflow: hidden;
					font-family: $openSans !important;
					margin-top: 16px;

					@include breakpoint($mobile, $desktop) {
						font-size: 16px !important;
						min-width: 100%;
					}
				}
			}
		}
	}

	.logo {
		position: absolute;
		bottom: 20px;
		left: calc(50% - 45px);
		width: 90px;
		height: 44px;

		@include breakpoint($mobile, $tablet) {
			position: relative;
			bottom: unset;
			left: unset;
			margin: 16px auto 0;
		}
	}

	.close {
		position: absolute;
		top: 40px;
		right: 40px;
		z-index: 1;
		transition: all 0.5s linear;

		@include breakpoint($mobile, $tablet) {
			top: 30px;
			right: 30px;
		}
	}

	&.hide {
		& > * {
			display: none;
		}
	}

	&.show {
		z-index: 10;
		height: 100%;
		top: 0;
		bottom: 0;
		overflow-x: hidden;

		& > * {
			display: block;
		}

		.form {
			display: flex;
		}
	}
}
