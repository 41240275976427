@import '../../../../styles/mixins';

.select-with-sections {
	position: relative;
	width: 100%;

	.trigger {
		position: relative;
		cursor: pointer;
		width: 275px;
		padding-top: 16px;
		z-index: 10;

		.value {
			font-size: 14px;
			line-height: 19px;
			padding: 8px 0;
			border-bottom: 0.5px solid $black;
			position: relative;

			& > .label {
				color: $dustyGray;
				font-weight: $semiBold;
				font-size: 10px;
				text-transform: uppercase;
				position: absolute;
				left: 0;
				top: -16px;
				transition: top 0.25s linear;
			}

			&.empty {
				& > .label {
					top: -10px;
					text-transform: none;
					font-weight: $light;
					font-size: 14px;
					letter-spacing: 0.5px;
					color: $dustyGray;
				}
			}
		}

		.icon {
			@include absolute(auto, 0, 9px, auto);
			transition: transform 0.35s;
			z-index: 10;

			&::before {
				content: '';
				display: inline-block;
				width: 0;
				height: 0;
				border: {
					color: $abbey transparent transparent transparent;
					width: 8px 6px 0 6px;
					style: solid;
				}
			}

			&.closed {
				transform: rotate(0);
			}

			&.opened {
				transform: rotate(-180deg);
			}
		}
	}

	.dropdown {
		@include absolute(-16px, auto, auto, -8px);
		z-index: -1;
		padding: 70px 0 0;
		min-width: 290px;
		opacity: 0;
		border: 1px solid $silver;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
		transition: transform 0.35s, opacity 0.1s;
		transform: translateY(-10%);

		&.empty-value {
			padding-top: 55px;
		}

		.content {
			background-color: $white;
			padding-bottom: 28px;
		}

		.section {
			position: relative;
			padding-bottom: 16px;
			margin-bottom: 16px;

			&:after {
				position: absolute;
				content: '';
				border-bottom: 1px solid $silver;
				bottom: 0;
				left: 8px;
				right: 8px;
			}

			&:last-child {
				margin: 0;
				padding: 0;

				&:after {
					border-bottom: none;
				}
			}

			.option {
				font-size: 14px;
				line-height: 19px;
				padding: 8px;
				transition: background 0.15s;

				&:hover {
					background: $blackHaze;
					cursor: pointer;
				}
			}
		}

		&.show {
			z-index: 4;
			opacity: 1;
			transform: translateY(0);
		}
	}
}
