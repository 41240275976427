@import '../../../../styles/variables';

.legend {
	margin: {
		bottom: 25px;
	}
	padding: 0;
	font: {
		family: $roboto;
		size: 28px;
		weight: $semiBold;
	}
	color: $tuna;
	opacity: 0.55;
	letter-spacing: 0.45px;

	&.small {
		margin: {
			bottom: 16px;
		}
		font: {
			size: 18px;
		}
	}

	&.extra-small {
		margin: {
			bottom: 16px;
		}
		font: {
			size: 16px;
		}
	}

	&.legend__extra-margin {
		margin: {
			bottom: 32px;
		}
	}
}
