@import '../../../../styles/variables';

.link {
	display: flex;
	align-items: center;
	font: {
		family: $openSans;
		size: 16px;
		weight: $semiBold;
	}
	color: $downy;
	text-decoration: none;
	transition: color 0.35s;

	&.disabled {
		opacity: 0.4;
	}

	.icon {
		margin: {
			right: 16px;
		}

		&::before {
			display: inline-block;
			font: {
				size: 21px;
			}
			transition: color 0.35s;
			color: $wedgewood;
		}
	}

	&:not(.disabled):hover {
		&,
		i::before {
			color: $comet;
		}
	}
}
