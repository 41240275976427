@import '../../../styles/mixins';

.wrap {
	position: relative;
	border: {
		bottom: 0 !important;
	}

	.button {
		width: 40px;
		margin: {
			right: 8px;
		}
		background: transparent;
		border: {
			top: 0;
			right: 0;
			bottom: 1px solid rgba(0, 0, 0, 0.6);
			left: 0;
			radius: 0;
		}
	}

	.input {
		width: calc(100% - 48px);
		background: transparent;
		margin: {
			left: 48px;
		}
		padding: {
			left: 0;
		}
		border: {
			top: 0;
			right: 0;
			bottom: 1px solid rgba(0, 0, 0, 0.6);
			left: 0;
			radius: 0;
		}
		color: $black;
	}

	.label {
		margin: {
			left: 50px;
		}
	}
}
