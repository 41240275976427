@import '../../../../styles/variables';

.applicants-chart {
	.info-box {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		max-width: 256px !important;
		background: $white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 24px;
		transition: opacity 0.2s linear;
		z-index: -1;

		.info-content {
			line-height: 1.4;
			font-size: 14px;

			.title {
				font-size: 14px;
				font-weight: $demiBold;
				margin-bottom: 8px;
			}

			.info {
				.text + .text {
					margin-top: 4px;
				}
			}
		}
	}

	:global {
		.xAxis .recharts-cartesian-axis-ticks {
			.recharts-cartesian-axis-tick:first-child {
				transform: translateX(5px);
			}

			.recharts-cartesian-axis-tick:last-child {
				transform: translateX(-5px);
			}
		}
	}

	&.one-item {
		:global {
			.xAxis .recharts-cartesian-axis-ticks {
				.recharts-cartesian-axis-tick {
					transform: translateX(5px);
				}
			}
		}
	}
}
