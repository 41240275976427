@import '../../../styles/variables';

.arc {
	width: 238px;
	height: 120px;
	background: url('../../../assets/images/score-arc.svg');
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;

	&:before,
	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		text-transform: uppercase;
		top: 55%;
		font-size: 14px;
		font-weight: $demiBold;
		font-family: $openSans;
		letter-spacing: 2px;
	}

	&:before {
		content: 'Low';
		color: $turquoiseLight;
		left: -22px;
		transform: rotateZ(-70deg);
	}

	&:after {
		content: 'High';
		color: $cyanAzure;
		right: -24px;
		transform: rotateZ(70deg);
	}

	.gauge {
		display: block;
		position: absolute;
		bottom: 4px;
		left: calc(50% - 8px);
		width: 17px;
		height: 100px;
		filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
		background: url('../../../assets/images/gauge.svg');
		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: bottom center;
		transition: all 0.6s ease-in-out;
	}

	&.small {
		width: 201px;
		height: 102px;

		&:before,
		&:after {
			font-size: 10px;
		}

		&:before {
			left: -16px;
		}
	
		&:after {
			right: -20px;
		}

		.gauge {
			height: 85px;
		}
	}
}
