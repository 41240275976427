@import '../../../../styles/variables';

.certificate-badges {
	.content-box-position {
		top: 22px;
		width: 371px !important;
		min-width: 371px !important;
	}

	.badges-content {
		.title {
			font-size: 14px;
			font-weight: $demiBold;
			margin-bottom: 16px;
		}

		.subtitle {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			& + .subtitle {
				margin-top: 6px;
			}

			.text {
				font-size: 14px;
				font-weight: $normal;
				letter-spacing: -0.2px;

				&.link {
					&:before {
						display: none;
					}
				}
			}
		}
	}

	.other-certificates {
		color: $downy;
		font-size: 14px;
		font-weight: $demiBold;
	}
}
