@import '../../../../styles/variables';

.templates-settings {
	padding-bottom: 30px;
	
	.content-wrapper {
		max-width: 550px;
	}

	.description {
		margin-bottom: 32px;
		font-weight: $normal;

		& > .note {
			font-style: italic;
		}
	}

	.template-options {
		margin-bottom: 32px;
	}

	.section-title {
		color: $black !important;
		font-size: 14px !important;
		font-weight: $normal !important;
	}
}

.loader {
	display: flex;
	margin-top: 15%;
}