@import '../../../../styles/mixins';

%arrow {
	&::before {
		@include size(11px);
		border: {
			color: $downy;
		}
	}

	&.disabled {
		cursor: default;

		&::before {
			border: {
				color: $silver;
			}
		}
	}
}

.nav {
	display: flex;

	.back {
		@extend %arrow;
	}

	.next {
		@extend %arrow;
		transform: rotate(180deg);
	}

	.counter {
		font: {
			family: $roboto;
			weight: $normal;
			size: 16px;
		}
		color: $white;
		padding: 0 14px;

		@include breakpoint($mobile, $tablet) {
			padding: 0 6px;
		}
	}
}
