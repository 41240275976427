@import '../../../../styles/variables';

.wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: {
		top: 180px;
	}
	text-align: left;

	.content {
		.title {
			line-height: 1;
		}

		.desc {
			margin: {
				left: 10px;
			}
			font: {
				size: 37px;
				weight: $light;
			}
		}
	}
}
