@import '../../../../styles/variables';

.error-notification {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 5;
	display: block;
	width: 100%;
	background: $wildSand;
	padding: 20px;
	text-align: center;

	.message {
		font: {
			size: 14px;
			weight: $demiBold;
		}

		color: $bloodyRedApricot;
	}
}
