@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.profiles {
	margin-top: 24px;
	padding-bottom: 32px;
}

.desc {
	text-align: center;
	width: 50%;
	margin: 24px auto;

	@include breakpoint($mobile, $tablet) {
		width: 100%;
	}
}

.list {
	padding-left: 30%;
	text-align: left;

	@include breakpoint($mobile, $tablet) {
		padding-left: 15%;
	}
}

.tiles {
	display: flex;
	flex-wrap: wrap;

	@include breakpoint($mobile, $desktop) {
		justify-content: center;
	}
}

.popover-width {
	min-width: 295px !important;
	width: 295px !important;
	top: 18px !important;
}

.info-box {
	margin-left: 12px;
	display: inline;
	line-height: 16px;
}

.info-content {
	.title {
		font-size: 14px;
		font-weight: $demiBold;
		margin-bottom: 8px;
	}

	.text {
		font-size: 14px;
		line-height: 20px;

		& + .text {
			margin-top: 8px;
		}
	}

	.fitscore {
		margin: 24px 0 0;
		display: flex;
		justify-content: center;

		.score-content {
			margin-bottom: 0;
		}
	}
}

.empty-text {
	font-size: 16px !important;
	text-align: center;
	margin-top: 80px;

	@include breakpoint($mobile, $tablet) {
		margin-top: 40px;
	}
}

.loader {
	display: flex;
	margin-top: 60px;
}
