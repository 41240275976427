@import '../../../styles/mixins';

.get-started {
	max-width: 720px;
	margin: 32px auto;
	text-align: center;

	@include breakpoint($mobile, $tablet) {
		max-width: 85%;
	}

	.title {
		@include breakpoint($mobile, $tablet) {
			font-size: 34px !important;
		}
	}

	.desc {
		max-width: 460px;
		font-size: 16px;
		font-weight: $normal;
		margin: 0 auto 60px;

		.link {
			font-weight: $demiBold;
		}
	}

	.form {
		margin: 0 auto;

		.inputs {
			max-width: 720px;
			margin: 0 auto 48px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			@include breakpoint($mobile, $tablet) {
				flex-direction: column;
			}

			.group {
				width: 330px;

				@include breakpoint($mobile, $tablet) {
					width: 100%;

					&:not(:last-child) {
						margin: {
							bottom: 32px;
						}
					}
				}
			}
		}

		.input {
			width: 100%;

			&:not(:last-child) {
				margin: {
					bottom: 40px;
				}
			}
		}

		.terms {
			color: $tuna;
			font-size: 14px;
		}
	}

	.error {
		margin-top: 32px;
	}

	.btn {
		display: block;
		margin: 32px auto 0;

		@include breakpoint($mobile, $tablet) {
			max-width: 100%;
		}
	}

	.remark {
		line-height: 24px;
		padding: 32px 0;
		font-size: 14px;

		.link {
			font-size: 14px;
			font-weight: $demiBold;
		}
	}

	.sparke {
		position: fixed;
		bottom: 50px;
		right: 50px;

		@include breakpoint($mobile, $tablet) {
			position: relative;
			bottom: 0;
			right: 0;
			margin-bottom: 32px;
		}
	}
}
