@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.switcher {
	@include size(32px, 20px);
	position: relative;
	display: inline-block;
	background: {
		color: rgba($abbey, 0.15);
	}
	border: {
		radius: 10px;
	}
	cursor: pointer;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&::before {
		@include absolute(1px, auto, auto, 1px);
		@include size(18px);
		display: inline-block;
		border: {
			radius: 50%;
		}
		background: {
			color: $white;
		}
		content: '';
		transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	@at-root &__wrap {
		.input {
			display: none;

			&:checked + .label > .switcher {
				background: {
					color: $downy;
				}

				&::before {
					left: 13px;
				}
			}
		}

		.label {
			display: inline-flex;
			font: {
				family: $openSans;
				size: 18px;
			}
			color: $tundora;

			& > .switcher {
				margin: {
					left: 38px;
				}
			}
		}
	}
}
