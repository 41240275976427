@import '../../../styles/variables';
@import '../../../styles/mixins';

.dropdown-content {
	@include absolute(auto, 70%, 0, auto);
	z-index: -1;
	display: inline-flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	padding: 32px 20px;
	min-width: 269px;
	opacity: 0;
	background: {
		color: $white;
	}
	box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1);
	transition: all 0.35s;
	transform: translateX(50%) translateY(100%);

	@include breakpoint($mobile, $tablet) {
		right: 90%;
	}

	> :not(:last-child) {
		margin: {
			bottom: 24px;
		}
	}

	&.open {
		z-index: 4;
		bottom: -24px;
		opacity: 1;
	}
}
