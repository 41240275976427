@import '../../../styles/variables';
@import '../../../styles/mixins';

.detail-layout {
	.content {
		width: 44%;
		margin: {
			right: 29%;
		}
		float: left;

		@include breakpoint($mobile, $tablet) {
			width: 100%;
			margin: 0;
		}
	}

	.sidebar {
		width: 27%;
		float: left;

		@include breakpoint($mobile, $tablet) {
			width: 100%;
			margin-top: 32px;
			padding-top: 32px;
			border-top: 1px solid $black;
		}
	}
}
