@import '../../../styles/mixins';

.wrap {
	@extend %clearfix;
	position: relative;
	z-index: 0;

	.actions {
		display: flex;
		align-items: center;

		.loader {
			margin: {
				left: 10px;
			}
		}
	}

	.slash {
		@extend %slash;
		margin: 0 8px;
	}

	.btn {
		font: {
			family: $openSans;
			size: 16px;
			weight: $semiBold;
		}
	}

	.desc {
		margin: {
			top: -14px;
			bottom: 24px;
		}
	}

	&.edit {
		z-index: 10;
	}

	.content {
		position: relative;
		z-index: 1;
	}

	&:not(:last-child) {
		margin: {
			bottom: 16px;
		}
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: {
			bottom: {
				width: 0.5px;
				style: solid;
				color: $black;
			}
		}
		margin: {
			bottom: 32px;
		}
		padding: 8px 0;
	}

	.title {
		font: {
			size: 20px;
			weight: $demiBold;
			family: $roboto;
		}
		color: rgba($tuna, 0.55);
		letter-spacing: 0.45px;
	}

	.form-item {
		float: left;
		margin: {
			bottom: 40px;
		}

		&:not(:nth-child(2n)) {
			margin: {
				right: 0;
			}
		}

		&:nth-child(2n) {
			margin: {
				left: 64px;
			}
		}
	}
}
