@import '../../../../styles/mixins';

.progress-container {
	width: 100%;
	height: 16px;
	border-radius: 8px;
	box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.15);
	background: $alabaster;

	.progress {
		display: inline-block;
		height: 100%;
		border-radius: 8px;
		background: linear-gradient(110deg, $turquoise 17.55%, $wedgewood 85%);
		box-shadow: inset 1px 4px 2px rgba(0, 0, 0, 0.15);
		background-size: cover;
		background-repeat: no-repeat;
		transition: all 0.6s ease-in-out;
	}
}
