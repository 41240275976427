@import '../../../../styles/variables';

.error {
	display: block;
	font: {
		size: 12px;
	}
	text-align: center;
	color: $bloodyRedApricot;

	&:first-letter {
		text-transform: uppercase;
	}
}
