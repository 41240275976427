@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.wrap {
	display: flex;
	flex-direction: column;
	margin: auto 0;
	text-align: left;

	.job-name {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font: {
			family: $openSans;
			size: 16px;
			weight: $bold;
		}
		color: $white;

		@include breakpoint($mobile, $tablet) {
			font-size: 14px;
		}
	}

	.job-location {
		font: {
			family: $openSans;
			size: 16px;
			weight: $light;
		}
		color: $white;

		@include breakpoint($mobile, $tablet) {
			font-size: 12px;
		}
	}
}
