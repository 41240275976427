@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.popup-header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 88px;
	background: {
		color: $apricot;
	}
	padding: 25px 234px;
	color: $white;
	text-align: center;

	@include breakpoint($mobile, $tablet) {
		padding: 25px 20px;
		padding-right: 40px;
	}

	&.has-logo {
		min-height: 88px;
	}

	.logo {
		@include absolute(50%, auto, auto, 76px);
		display: inline-block;
		transform: translateY(-50%);
		max-width: 160px;

		img {
			width: 100%;
		}

		@include breakpoint($mobile, $tablet) {
			max-width: 60px !important;
			left: 30px;
		}
	}

	.title {
		letter-spacing: 0.45px;

		@include breakpoint($mobile, $tablet) {
			max-width: 50%;
		}
	}

	.title-logo {
		& > img {
			width: 100%;
			max-height: 45px;
			
			@include breakpoint($mobile, $tablet) {
				max-width: 85%;
			}
		}
	}

	.back {
		@include absolute(50%, auto, auto, 76px);
		transform: translateY(-50%);

		@include breakpoint($mobile, $tablet) {
			left: 32px;
		}
	}

	.close {
		@include absolute(50%, 76px, auto, auto);
		transform: translateY(-50%);

		&::before,
		&::after {
			background: {
				color: $white;
			}
		}

		@include breakpoint($mobile, $tablet) {
			@include absolute(50%, 30px, auto, auto);
		}
	}
}
