@import '../../styles/variables';
@import '../../styles/mixins';

.candidate-rejection {
	.container {
		padding-left: 0;
		padding-right: 0;
	}

	.calendar-link-success {
		margin-top: 40px;
		font-style: italic;
		font-size: 14px;
		padding-bottom: 24px;
		border-bottom: 1px solid $silver;
	}

	.title {
		margin: 56px 0 24px;
		font-size: 19px;

		&.with-top-section {
			margin-top: 24px;
		}
	}

	.names-section {
		padding: 24px 0;
		margin-bottom: 36px;
		border-top: 1px solid $silver;
		border-bottom: 1px solid $silver;

		.candidate {
			font-size: 16px;
			font-weight: $demiBold;
		}

		.candidates {
			.candidate {
				margin-right: 4px;
			}

			.others-names {
				padding-top: 24px;

				.name {
					font-size: 14px;
					font-weight: $normal;

					& + .name {
						margin-top: 10px;
					}
				}
			}
		}
	}

	.reason {
		max-width: 290px;
	}

	.switcher {
		margin: 34px 0 16px;

		label {
			font-size: 14px !important;
			line-height: 20px;
		}
	}

	.description {
		font-style: italic;
		font-size: 12px;
		line-height: 16px;
		margin-bottom: 16px;
		color: $tuna;
		font-weight: $light;
	}

	.actions {
		margin-top: 68px;
		padding-bottom: 60px;
		
		@include breakpoint($mobile, $tablet) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.btn {
			@include breakpoint($mobile, $tablet) {
				margin-bottom: 16px;
				margin-right: 0;
			}
		}
	}

	.subject-editor {
		margin-bottom: 30px;
		margin-top: 40px;

		.label {
			color: $dustyGray;
			font-size: 10px;
			font-weight: $semiBold;
			text-transform: uppercase;
			margin-bottom: 8px;
		}
	}
}

.loader {
	display: flex;
	margin-top: 5%;
}
