@import '../../../styles/variables';

.title {
	margin: 0;
	font: {
		family: $openSans;
	}

	&.roboto {
		font: {
			family: $roboto;
		}
	}
}
