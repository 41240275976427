@import '../../../styles/variables';
@import '../../../styles/mixins';

.content-with-sidebar {
	@extend %clearfix;
	display: flex;
	flex-grow: 1;
	height: 100%;

	@include breakpoint($mobile, $tablet) {
		flex-direction: column;
	}

	.content {
		flex-grow: 1;
		width: 70%;
		padding: {
			top: 40px;
			right: 15%;
			left: 70px;
		}
		float: left;

		@include breakpoint($mobile, $desktop) {
			flex-grow: initial;
			width: 100%;
			padding-left: 30px !important;
			padding-right: 30px !important;
			float: none;
		}
	}

	.sidebar {
		flex-grow: 1;
		width: 30%;
		padding: 40px 65px;
		float: left;

		@include breakpoint($mobile, $desktop) {
			flex-grow: initial;
			width: 100%;
			float: none;
			padding: 30px 65px;
		}
	}
}
