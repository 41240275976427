@import '../../../../styles/variables';

.info-popover {
	position: relative;
	cursor: pointer;
	text-transform: none;
	font-family: $roboto;

	&.default-trigger:before {
		position: absolute;
		top: calc(50% - 12px);
		left: -10px;
		content: url('../../../../assets/icons/info-in-circle.svg');
		width: 15px;
		height: 15px;
		padding: 5px;
	}

	.content-box {
		cursor: auto;
		display: none;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background: $white;
		padding: 24px;
		width: max-content;
		min-width: 400px;
		max-width: 450px;
		color: $tunaDark;
		font-weight: $normal;
		font-size: 14px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		z-index: -1;
	}

	&:hover {
		.content-box {
			display: block;
			z-index: 10;
			animation: fadeIn 0.25s ease-in-out;
		}
	}
}
