@import '../../../styles/variables';
@import '../../../styles/mixins';

.show-more-dropdown {
	position: relative;

	.trigger {
		position: relative;
		cursor: pointer;
		display: inline-block;

		.chevron {
			@include absolute(-1px, -36px, auto, auto);
			height: 24px;
			transform: rotate(180deg) scale(0.4);
			transition: all 0.2s ease-in-out;

			&:before {
				border: {
					width: 4px 4px 0 0;
				}
				color: $downy;
			}
		}

		&.opened {
			.chevron {
				top: -3px;
				transform: rotate(270deg) scale(0.4);
			}
		}
	}

	.content {
		visibility: hidden;
		opacity: 0;
		max-height: 0;
		overflow: hidden;
		transition: all 0.3s ease-in-out;

		&.opened {
			visibility: visible;
			opacity: 1;
			max-height: 800px;
		}
	}
}
