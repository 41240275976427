@import '../../../styles/mixins';

$radius: 10px;

.wrap {
	position: relative;
	display: inline-flex;
	width: 100%;
	overflow: hidden;
	border: {
		radius: #{$radius};
	}

	&.loading {
		&::before {
			@include absolute(0, 0, 0, 0);
			z-index: 2;
			content: '';
			background: {
				color: rgba($black, 0.2);
			}
			transition: background-color 350ms;
		}
	}

	* {
		transition: color 350ms;
	}

	.tile {
		width: 100%;
		height: 100%;
	}

	.preview {
		width: 100%;
		height: 100%;
	}

	&:hover * {
		color: $abbey;
	}
}

.input {
	display: none;

	& + label {
		@include absolute(0, 0, 0, 0);
		display: block;
		z-index: 0;
		width: 100%;
		height: 100%;
		border: 0;
		opacity: 0;
		border: {
			radius: #{$radius};
		}
		font: {
			size: 0;
		}
	}

	&:not([disabled]) + label {
		cursor: pointer;
	}
}
