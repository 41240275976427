.wrap {
	.content {
		overflow: hidden;
		opacity: 0;

		&.loaded {
			opacity: 1;
		}
	}

	.button {
		outline: 0;
		margin: {
			top: 5px;
		}
	}
}
