@import '../../../../styles/mixins';

.candidates {
	position: relative;
	min-height: calc(100vh - 302px);

	.top-bar {
		padding: {
			left: 0;
			right: 0;
		}

		@include breakpoint($mobile, $desktop) {
			flex-wrap: wrap;
		}

		.right {
			display: flex;
			align-items: center;

			@include breakpoint($mobile, $tablet) {
				flex-wrap: wrap;
				margin-top: 16px;
			}

			.btn {
				min-height: 37px;
				max-height: 37px;
				margin: {
					right: 24px;
				}

				@include breakpoint($mobile, $tablet) {
					margin-bottom: 16px;
					min-width: 100px !important;
					margin-right: 16px;
				}
			}

			.candidates-export-info {
				font: {
					size: 16px;
				}
			}

			.status-select {
				@include breakpoint($mobile, $tablet) {
					margin-bottom: 16px;
				}
			}
		}
	}

	.checkbox {
		position: relative;
		top: 2px;
	}

	.ordinary-text {
		text-transform: none;
	}

	.highlighted {
		color: $redApricot;
	}

	.views {
		width: 100%;
		text-transform: lowercase;
		font-weight: $semiBold;
		font-size: 14px;

		&.regular {
			font-weight: $normal;
		}
	}

	.time {
		width: 100%;
	}
	
	.info-box {
		margin-left: 16px;

		@include breakpoint($tablet, $desktop) {
			display: none;
		}
	}

	.info-content {
		.title {
			font-size: 14px;
			font-weight: $demiBold;
			margin-bottom: 8px;
		}

		.subtitle {
			margin-bottom: 8px;

			.subtitle-text {
				font-size: 14px;
				font-weight: $normal;
				vertical-align: middle;
			}
		}

		.text {
			line-height: 20px;

			.remark {
				margin-top: 8px;
				font-style: italic;
			}
		}

		.learn-more {
			font-size: 14px;
			margin-top: 8px;
			display: inline-block;

			&::before {
				display: none;
			}
		}
	}

	.popover-width {
		width: 295px !important;
		min-width: 295px !important;
	}

	.views-info {
		.content-box-position {
			top: 16px;
		}

		.views-content {
			.title {
				font-size: 14px;
				font-weight: $demiBold;
				margin-bottom: 16px;
			}

			.view {
				display: flex;
				flex-direction: row;

				& + .view {
					margin-top: 6px;
				}

				.view-item {
					font-size: 14px;
					font-weight: $normal;
				}

				.time {
					color: $mistGray;
					margin-left: 6px;
				}
			}
		}

		.other-certificates {
			color: $downy;
			font-size: 14px;
			font-weight: $demiBold;
		}
	}

	.sortable {
		&:hover {
			.hidden {
				display: block;
			}
		}

		.hidden {
			display: none;
			animation: fadeIn 0.5s;
		}

		.intent-from-info {
			margin-left: 18px;
		}
	}

	.boost-job {
		margin-top: 50px;
	}

	.boost-job-form {
		margin: 0;
	}

	.empty-info {
		max-width: 550px;

		@include breakpoint($mobile, $desktop) {
			max-width: 100%;
		}
	}

	.publish-btn {
		display: block !important;
		margin-top: 24px;
	}
}
