@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.nav-link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 159px;
	max-height: 50px;
	border: {
		bottom: 3px solid transparent;
	}
	padding: 10px;
	font: {
		family: $openSans;
		size: 16px;
		weight: $bold;
	}
	color: $emperor;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.3s;
	box-sizing: border-box;

	@include breakpoint($mobile, $tablet) {
		min-width: 100px;
		max-height: 40px;
		font-size: 14px;
		padding: 8px;
	}

	&.disabled {
		opacity: 0.4;
		cursor: default;
	}

	&:not(:last-child) {
		margin: {
			right: 8px;
		}
	}

	&.active {
		border: {
			bottom: {
				color: $confetti;
			}
		}

		&.child-rout {
			border: {
				bottom: {
					color: $silverChalice;
				}
			}
		}
	}
}
