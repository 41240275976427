@import '../../../../styles/variables';

.fieldset {
	border: 0;
	margin: {
		left: 0;
		right: 0;
	}
	padding: 0;

	.fieldset {
		margin: {
			bottom: 40px;
		}
	}
}
