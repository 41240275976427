@import '../../../styles/variables';
@import '../../../styles/mixins';

.modal-window {
	@include fixed(0, 0, 0, 0);
	z-index: 5;
	background: {
		color: rgba($black, 0.4);
	}

	.content {
		@include absolute(50%, auto, auto, 50%);
		min-width: 440px;
		max-height: 100%;
		min-height: 200px;
		padding: 23px 45px;
		background: $white;
		transform: translateX(-50%) translateY(-50%);
		overflow-y: auto;

		@include breakpoint($mobile, $tablet) {
			min-width: 200px;
			padding: 20px;
		}
	}

	.close {
		@include absolute(15px, 10px, auto, auto);

		transform: scale(0.5);
	}
}
