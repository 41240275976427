@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.status-wrap {
	width: 100%;
}

.boost-status {
	position: relative;
	padding-left: 25px;

	@include breakpoint($mobile, $desktop) {
		padding-left: 20px;
	}

	&:before {
		content: '';
		position: absolute;
		top: calc(50% - 11.5px);
		left: 0;
		width: 15px;
		height: 23px;
	}

	&.active {
		&:before {
			background: url('../../../../assets/icons/spark-boosted.svg') no-repeat center center;
		}
	}
}
