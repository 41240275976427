@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.wrapper {
    display: flex;
    align-items: center;

    .experience {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @include breakpoint($mobile, $desktop) {
            flex-direction: column;
        }

        .item {
            position: relative;
            display: flex;
            min-height: 28px;
            align-items: center;
            margin: 0;
            padding: 0 16px;

            @include breakpoint($mobile, $desktop) {
                padding: 6px 0;
            }

            & + .item {
                @extend %slash;

                &:before {
                    @include absolute(50%, auto, auto, 0);
                    transform: translateY(-50%);
                    width: 1px;
                    background-color: $black;

                    @include breakpoint($mobile, $desktop) {
                        display: none;
                    }
                }
            }

            &.item-with-tooltip:hover {
                position: relative;
                cursor: pointer;

                .item-tooltip {
                    display: block;
                    z-index: 10;
                }
            }

            @include breakpoint($tablet, 1196px) {
                &:last-child:not(.certificates) {
                    margin: {
                        left: -13px;
                    }
                    padding: {
                        right: 21px;
                    }
                }
            }

            .icon {
                position: relative;
                top: -2px;
                margin: {
                    right: 10px;
                }
            }

            &:first-child {
                margin: {
                    left: -16px;
                }

                @include breakpoint($mobile, $desktop) {
                    margin: {
                        left: 0;
                    }
                }
            }

            @include breakpoint(1196px) {
                &:not(:last-child) {
                    position: relative;
                }
            }

            strong {
                margin: {
                    right: 4px;
                }
            }
        }

        .popover-position {
            top: 24px;
        }
    }

    .blue-icon {
        i::before {
            color: $blueChill;
            font: {
                size: 28px;
            }
        }

        &:not(.disabled):hover {
            i::before {
                color: $comet;
            }
        }
    }
}
