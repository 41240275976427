@import '../../../../styles/variables';

.statistic-top-bar-item {
	display: inline-block;
	text-align: center;

	&:not(:last-child) {
		margin: {
			right: 78px;
		}
	}

	.text {
		font: {
			family: $roboto;
			size: 14px;
			weight: $bold;
		}
		color: $white;
	}
}
