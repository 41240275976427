@import '../../../../../styles/mixins';

.custom-item {
	margin-left: 8px;
	cursor: pointer;
	
	:global(.rdw-storybook-custom-option) {
		position: relative;
	}
}
