.wrap {
	&.window {
		min-width: 388px;
		max-width: 388px;
		min-height: 277px;
		padding: 32px 34px 50px;
		border: {
			radius: 20px;
		}

		&.loader-only {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.btn {
			margin: {
				top: 30px;
			}
		}
	}
}
