@import '../../../../styles/variables';

.header {
	z-index: 1;
	width: 100%;
	min-height: 73px;
	padding: 0 70px;
	background: {
		color: $comet;
	}
}
