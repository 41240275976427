@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.dropdown-toggle {
	position: relative;
	display: inline-flex;
	align-items: center;
	margin: {
		top: auto;
	}
	padding: 0 35px 0 0;
	border: 0;
	background: {
		color: transparent;
	}
	outline: {
		color: transparent;
	}
	font: {
		family: $openSans;
		size: 12px;
		weight: $semiBold;
	}
	color: rgba($black, 0.8);
	cursor: pointer;

	.chevron {
		@include absolute(calc(50% - 14px), 0, auto, auto);
		height: 24px;
		transform: rotate(-90deg) scale(0.4);
		transition: all 0.35s;

		&::before {
			border: {
				width: 3px 3px 0 0;
			}
			color: $black;
		}
	}

	&.open {
		.chevron {
			top: calc(50% - 10px);
			transform: rotate(-270deg) scale(0.4);
		}
	}
}
