@import '../../../styles/variables';
@import '../../../styles/mixins';

.statistic {
	display: flex;
	align-items: center;
	min-height: 173px;
	background: {
		color: $comet;
	}

	.container {
		align-items: center;

		@include breakpoint($mobile, $desktop) {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.left {
		display: flex;
		align-items: center;

		@include breakpoint($mobile, $desktop) {
			margin: 24px auto 0;
		}
	}

	.right {
		margin: {
			left: auto;
		}

		@include breakpoint($mobile, $desktop) {
			margin: 24px auto;
		}
	}
}
