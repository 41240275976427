//Here are placed reusable variables

//Fonts variables
$openSans: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
	'Droid Sans', 'Helvetica Neue', sans-serif;
$roboto: 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
	'Helvetica Neue', sans-serif;

//Font weights
$light: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$demiBold: 700;
$bold: 900;

//Resolutions
$mobile: 340px;
$tablet: 769px;
$desktop: 1024px;
$extraDesktop: 1270px;

/*Color variables
*Every color has it`s name
*For taking colors names was using this service "http://chir.ag/projects/name-that-color" */
$white: #fff;
$black: #000;
$mineShaft: #333;
$wildSand: #f4f4f4;
$wildSandDarker: #f2f2f2;
$calmSand: #fafafa;
$silver: #c5c5c5;
$silverLight: #e7e7e7;
$doveGray: #666;
$dustyGray: #979797;
$mistGray: #959595;
$emperor: #4f4f4f;
$silverChalice: #aeaeae;
$alabaster: #f8f8f8;
$abbey: #545558;
$tundora: #404040;
$comet: #525668;
$blackHaze: #f0f3f2;
$tuna: #2e3039;
$downy: #70c9c2;
$downyLight: #F0FBF4;
$wedgewood: #4986a1;
$pastelGreen: #6dd692;
$catchyGreen: #04A93E;
$apricot: #ee846d;
$redApricot: #ee7470;
$bloodyRedApricot: #EA002E;
$confetti: #efd35e;
$blueChill: #0e76a8;
$blueChillDark: #34708A;
$tunaDark: #3A3C3F;
$tunaDarkBlack: #373A3C;
$cyanAzureDark: #266580;
$cyanAzure: #4A87A2;
$turquoiseLight: #84EEF1;
$turquoise: #38DCE1;


@keyframes fadeIn {
	0% {
		display: none;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}