@import '../../../../styles/mixins';

.wrap {
	@extend %clearfix;
	width: 82%;
	min-height: 250px;
	margin-bottom: 0;
}

.item {
	width: calc(30% - 16px);
	float: left;

	&:first-child {
		width: 70%;
		height: 18.302vw;
		margin: {
			right: 16px;
		}
	}

	&:not(:first-child) {
		height: calc(9.2vw - 8px);
		margin: {
			bottom: 16px;
		}
	}
}

.error {
	color: $apricot;
	font-size: 12px;
	clear: both;
	position: relative;
	top: -16px;
}
