@import '../../../../styles/mixins';

.form {
	display: flex;
	align-items: flex-end;
	border: {
		bottom: 1px solid $wedgewood;
	}
	padding: {
		bottom: 30px;
	}

	input {
		@include autofill($black);
	}

	.btn {
		margin: {
			left: auto;
		}

		@include breakpoint($mobile, $tablet) {
			font-size: 10px;
		}
	}
}
