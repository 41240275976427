@import '../../../../styles/mixins';

.form-item {
	$wrap: &;
	@extend %formSelect;
	border: {
		bottom: 1px solid rgba($black, 0.6);
	}
	box-sizing: border-box;

	&.disabled.visible-disable-styles {
		@extend %visible-disable-styles;
	}

	&.disabled:not(.visible-disable-styles) {
		.icon {
			display: none;
		}
	}

	.icon {
		z-index: -1;
	}

	.label {
		@include absolute(12px, auto, auto, 0);
		z-index: -1;
		font: {
			size: 14px;
		}
		transition: all 0.3s;
	}

	&.up-label {
		.label {
			@include absolute(-13px, auto, auto, 0);
			font: {
				size: 10px;
				weight: $demiBold;
			}
			color: $dustyGray;
			text-transform: uppercase;
		}
	}

	&.error__wrap {
		@extend %errorWrapper;
	}

	.error {
		@extend %errorPosition;
	}
}
