@import '../../../../styles/mixins';

.wrap {
	@include size(24px);
	border: {
		width: 1px;
		style: solid;
		color: $downy;
		radius: 50%;
	}
	background: {
		color: $white;
	}
	color: $downy;
	transition: all 350ms;

	.close {
		@include size(22px, 22px);
		display: flex;
		align-items: center;
		padding: 0;

		&::before,
		&::after {
			left: 50%;
			width: 1px;
			height: 13px;
			background: {
				color: $downy;
			}
		}
	}

	&:hover {
		border: {
			color: $black;
		}

		.close::before,
		.close::after {
			width: 1px;
			background: {
				color: $black;
			}
		}
	}
}
