@import '../../styles/mixins';

.job {
	.tabs {
		@extend %content-shifting-up;
	}

	.tabs-wrap {
		@include breakpoint($mobile, $tablet) {
			border-bottom: 3px solid rgba($blackHaze, 0.5);
		}
	}
}

.loader {
	top: 74px !important;

	@include breakpoint($mobile, $tablet) {
		top: 93px !important;
	}
}
