@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.top-bar {
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	padding: 40px 70px;
	border-bottom: 4px solid $wedgewood;

	@include breakpoint($mobile, $desktop) {
		padding: 40px 30px;
		flex-direction: column;
		text-align: center;
	}

	.name {
		font-size: 27px;
		font-weight: $demiBold;
		color: $tuna;
	}

	.description {
		font-size: 14px;
		width: 42%;
		max-width: 545px;
		font-weight: $normal;

		@include breakpoint($mobile, $desktop) {
			width: 100%;
			margin-top: 16px;
		}
	}

	.actions {
		@include breakpoint($mobile, $desktop) {
			margin-top: 16px;
		}

		.btn + .btn {
			margin-left: 16px;

			@include breakpoint($mobile, $tablet) {
				margin: 10px;
			}
		}

		&.post-job {
			@include breakpoint($mobile, $tablet) {
				.btn {
					margin: 8px;
				}
			}
		}
	}
}

.preview {
	.top-page {
		padding-bottom: 28px;
		background: $calmSand;
		border-top: 1px solid $wildSand;
		border-bottom: 1px solid $wildSand;
	}

	.container {
		padding: 0 70px;
		max-width: 100%;
	}

	.details {
		overflow: hidden;
		padding: 0 70px 40px;
	}
}
