@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.loading {
	position: fixed;
	left: 0;
	right: 0;
	top: 100%;
	bottom: 0;
	height: 0;
	width: 100%;
	z-index: -10;
	padding: 0;
	margin: 0;
	background-color: $wedgewood;
	justify-content: center;
	align-items: center;

	&.with-animation {
		transition: all 0.4s linear;
	}

	.sparke {
		position: absolute;
		left: calc(50% - 150px);
		top: calc(50% - 150px);
	}

	.bubble-content {
		font-size: 14px;
		font-family: $roboto;
		font-weight: $demiBold;
		margin-top: 6px;
		text-align: center;
	}

	.loading-animation {
		margin: 0 auto;
		width: 146px;
		height: 146px;
		background: url('../../../../assets/images/sparke-loading-screen.gif');
		background-repeat: no-repeat;
		background-size: contain;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logo {
		position: absolute;
		bottom: 20px;
		left: 50%;
		width: 90px;
		height: 44px;

		@include breakpoint($mobile, $tablet) {
			left: calc(50% - 45px);
		}
	}

	&.hide {
		& > * {
			display: none;
		}
	}

	&.show {
		z-index: 10;
		height: 100%;
		top: 0;
		bottom: auto;

		& > * {
			display: block;
		}

		.loading-animation {
			display: flex;
		}
	}
}
