.info-list-item {
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		margin: {
			right: 44px;
		}
	}
}
