@import '../../../styles/variables';
@import '../../../styles/mixins';

.top-bar {
	display: flex;
	align-items: flex-end;
	margin: {
		top: 48px;
		bottom: 38px;
	}

	.left {
		display: flex;
		align-items: flex-end;
		margin: {
			right: auto;
		}

		> *:not(:last-child) {
			margin: {
				right: 10px;
			}
		}

		@include breakpoint($mobile, $tablet) {
			flex-wrap: wrap;
		}
	}

	.center {
		margin: 0 auto;
	}

	.right {
		margin: {
			left: auto;
		}
	}
}
