@import '../../../styles/variables';

.page-item {
	&.prev,
	&.next {
		display: inline-block;
		cursor: pointer;
		width: 11px;
		height: 17px;
		background-repeat: no-repeat;
		background-size: contain;
		vertical-align: middle;

		&.disabled {
			pointer-events: none;
		}
	}

	&.prev {
		background-image: url('../../../assets/icons/arrows/arrow-left-active.svg');

		&.disabled {
			background-image: url('../../../assets/icons/arrows/arrow-left-disabled.svg');
		}
	}

	&.pageNumber {
		font-weight: $normal;
		font-size: 16px;
		padding: 0 20px;
	}

	&.next {
		background-image: url('../../../assets/icons/arrows/arrow-right-active.svg');

		&.disabled {
			background-image: url('../../../assets/icons/arrows/arrow-right-disabled.svg');
		}
	}
}
