@import '../../styles/variables';

.boost-job {
	height: 100%;
	background: {
		color: $pastelGreen;
	}

	.header {
		background: {
			color: $pastelGreen;
		}
	}
}

.error-message {
	margin: 0px auto;
	background: #fff;
	padding: 30px;
	display: block;
	width: 500px;
	position: relative;
	top: 100px;
	border-radius: 8px;
}

.loader {
	display: flex;
	margin-top: 5%;
}