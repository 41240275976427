@import '../../../../styles/variables';

.company-settings {
	.content-wrapper {
		max-width: 550px;
	}

	.switcher {
		margin-bottom: 24px;

		label {
			font-size: 14px !important;
			line-height: 20px;
			font-weight: $normal;
		}
	}
}