@import '../../../../../styles/variables';

.form {
	display: flex;
	flex-direction: column;

	.heading {
		color: $black;
	}
}
