@import '../../styles/variables';

.login {
	display: flex;
	min-height: 100%;
	background: {
		color: $tuna;
	}
	text-align: center;

	.container {
		margin: auto;
	}

	.wrap {
		position: relative;
		top: -60px;
	}
}

.logo {
	display: inline-block;
	margin: {
		bottom: 13px;
	}
}

.close {
	color: $black;
}

.modal-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header {
	*:not(a):not(button) {
		color: $white;
	}
}
