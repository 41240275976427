@import '../../../../../styles/mixins';

.label {
	display: inline-block;
	font: {
		family: $openSans;
		size: 12px;
		weight: $normal;
	}
	color: $comet;
	text-align: right;

	@include breakpoint($mobile, $desktop) {
		text-align: left;
	}
}
