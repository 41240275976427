@import '../../../styles/mixins';

.wrap {
	@extend %downloadItemElements;
	display: flex;
	flex-direction: column;

	&:not(.auto-height) {
		min-height: 185px;
	}

	&.centered-content {
		text-align: center;
	}

	.list {
		margin: {
			bottom: 0;
		}
		padding: 0;
		list-style-type: none;

		&-item {
			position: relative;
			padding: {
				left: 10px;
			}
			font: {
				family: $roboto;
				size: 16px;
				weight: $normal;
			}
			color: $tuna;

			&::before {
				@include absolute(1px, auto, auto, 0);
				display: inline-block;
				content: '•';
			}
		}
	}

	.bottom {
		margin: {
			top: auto;
		}
	}
}
