@import '../../styles/variables';

.add-credits {
	height: 100%;
	background: {
		color: $comet;
	}

	.header {
		background: {
			color: $comet;
		};
		padding-top: 36px;
		padding-bottom: 48px;
	}
}

.error-message {
	margin: 0px auto;
	background: #fff;
	padding: 30px;
	display: block;
	width: 500px;
	position: relative;
	top: 30px;
	border-radius: 8px;
}

.loader {
	display: flex;
	margin-top: 5%;
}