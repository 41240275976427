@import '../../../styles/variables';
@import '../../../styles/mixins';

.loader {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.wide {
		@include fixed(0, 0, 0, 0);
		z-index: 3;
		background-color: $white;


		&.shadow {
			background-color: rgba($black, 0.2);
		}
	}
}
