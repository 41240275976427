@import '../../../../styles/variables';

.value-tag {
	font: {
		size: 14px;
		weight: $normal;
	}
	color: $wedgewood;
	text-transform: uppercase;
}
