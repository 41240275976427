@import '../../../../../styles/mixins';

.wrap {
	position: relative;
	width: 100%;
	height: 17px;
	background: #f0f3f2;
	border-radius: 0 50px 50px 0;
	margin: {
		bottom: 11px;
	}
	overflow: hidden;

	@include breakpoint($mobile, $desktop) {
		display: flex;
		flex-direction: column;
	}
}
