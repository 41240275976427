@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.sparke-speech {
	position: relative;
	padding-left: 50px;

	&:before {
		content: '';
		background-image: url('../../../../assets/icons/spark-e.svg');
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		left: 0;
	}

	&.small {
		&:before {
			bottom: -25px;
			left: -10px !important;
			height: 66px !important;
			width: 40px !important;
		}
	}

	&.medium {
		&:before {
			bottom: -35px;
			left: -40px !important;
			height: 98px !important;
			width: 65px !important;

			@include breakpoint($mobile, $desktop) {
				left: -10px !important;
				height: 66px !important;
				width: 40px !important;
			}
		}
	}

	.bubble {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	}
}
