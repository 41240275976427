@import '../../../../styles/variables';

.detail {
	margin: {
		top: 40px;
	}

	.item {
		* {
			color: var(--color-text-tertiary) !important;
		}

		a {
			font-weight: $semiBold;
		}

		&:not(:last-child) {
			margin: {
				bottom: 34px;
			}
		}

		.bottom-indent {
			margin: {
				bottom: 10px;
			}
		}

		&.salary {
			margin-top: 16px;
		}
	}

	.external-apply {
		font-size: 18px;
		font-weight: $normal;
		line-height: 1.5;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid $silver;
	}

	.company__logo {
		max-width: 100%;
		max-height: 100px;
	}
}
