@import '../../../styles/mixins';

.item {
	outline: 0;
	padding: {
		top: 20px;
	}

	a {
		@extend %link;
	}
}
