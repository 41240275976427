@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9clng9');
  src:  url('fonts/icomoon.eot?9clng9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9clng9') format('truetype'),
    url('fonts/icomoon.woff?9clng9') format('woff'),
    url('fonts/icomoon.svg?9clng9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-manager:before {
  content: "\e91d";
  color: #fff;
}
.icon-cultivation-manager:before {
  content: "\e91e";
  color: #fff;
}
.icon-dispensary-manager:before {
  content: "\e91f";
  color: #fff;
}
.icon-chevron:before {
  content: "\e900";
}
.icon-delete:before {
  content: "\e901";
}
.icon-duplicate:before {
  content: "\e902";
}
.icon-edit:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-medium-face:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e907";
}
.icon-phone:before {
  content: "\e908";
}
.icon-download:before {
  content: "\e909";
}
.icon-low-face:before {
  content: "\e90a";
}
.icon-high-face:before {
  content: "\e90b";
}
.icon-budtender-adult:before {
  content: "\e90c";
}
.icon-budtender-medical:before {
  content: "\e90d";
}
.icon-cultivation-associate:before {
  content: "\e90e";
}
.icon-custodian:before {
  content: "\e90f";
}
.icon-driver:before {
  content: "\e910";
}
.icon-edibles-associate:before {
  content: "\e911";
}
.icon-extraction-technician:before {
  content: "\e912";
}
.icon-generic-cannabis:before {
  content: "\e913";
}
.icon-inventory-specialist-retail:before {
  content: "\e914";
}
.icon-inventory-specialist-wholesale:before {
  content: "\e915";
}
.icon-maintenance-associate:before {
  content: "\e916";
}
.icon-packaging-associate:before {
  content: "\e917";
}
.icon-postharvest-production-associate:before {
  content: "\e918";
}
.icon-processing-associate:before {
  content: "\e919";
}
.icon-security-guard:before {
  content: "\e91a";
}
.icon-trimmer:before {
  content: "\e91b";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-link:before {
  content: "\e91c";
}
