@import '../../../styles/variables';
@import '../../../styles/mixins';

.close {
	$closeSIze: 28px;
	@include size($closeSIze);
	position: relative;
	border: none;
	background: none;
	display: inline-flex;
	justify-content: center;
	font: {
		size: 0;
	}
	cursor: pointer;

	&::before,
	&::after {
		@include absolute(auto, auto, auto, auto);
		content: '';
		display: inline-block;
		width: 2px;
		height: $closeSIze;
		background: {
			color: $black;
		}
		border: {
			radius: 4px;
		}
		transition: background-color 0.35s;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}

	&:hover {
		&::before,
		&::after {
			background: {
				color: $downy;
			}
		}
	}

	&.contrast {
		&::before,
		&::after {
			background: {
				color: $downy;
			}
		}

		&:hover {
			&::before,
			&::after {
				background: {
					color: $black;
				}
			}
		}
	}
}
