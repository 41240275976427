@import '../../../../../styles/variables';

.eoe {
	margin-bottom: 80px !important;
}

.error {
	margin-top: 8px;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
}
