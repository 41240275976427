@import '../../../../styles/mixins';

.input {
	width: 100%;
	background: {
		color: transparent;
	}

	&.input-has-loader {
		padding: {
			right: 30px;
		}
	}
}

.spinner {
	@include absolute(10px, 0, auto, auto);
}
