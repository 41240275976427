@import '../../../../styles/variables';

.label {
	font: {
		family: $roboto;
		weight: $light;
		size: 14px;
	}
	letter-spacing: 0.5px;
	color: $dustyGray;
}
