@import '../../../../styles/mixins';

.title {
	color: $tuna;
	font: {
		size: 3.22vw !important;
		weight: $normal;
	}
	text-align: center;
}

.description {
	position: relative;
	font: {
		size: 14px;
		weight: $light;
	}

	.error {
		font: {
			weight: $normal;
		}
		width: 60%;
		margin: 10px auto 0;
	}
}

.date {
	label {
		position: relative;
		z-index: -1;
	}
}

.fieldset {
	&:not(:last-child) {
		margin: {
			bottom: 56px;
		}
	}

	.legend {
		margin: {
			bottom: 42px;
		}
		font: {
			family: $openSans;
			size: 20px;
			weight: $bold;
		}
		color: $black;
	}

	.form-item {
		@extend %form-item-bottom-indent;
	}
}

.actions {
	margin: {
		top: 26px;
	}
}
