@import '../../../../styles/variables';

.description {
	margin: {
		left: 7px;
	}
	font: {
		size: 16px;
		weight: $bold;
	}
	color: $tuna;
	letter-spacing: 0.45px;
	line-height: 1.4;
}
