@import '../../../../styles/mixins';

.link {
	@extend %link;
	font-size: 14px;
	font-weight: $demiBold;
	margin-bottom: 12px !important;
}

.logout {
	font-weight: $demiBold;
}

.link + .logout {
	margin-top: 12px;
}

.btn {
	border: 0;
	background: {
		color: transparent;
	}
	font: {
		family: $openSans;
		size: 14px;
		weight: $demiBold;
	}
	cursor: pointer;
	transition: color 0.35s;
	@extend %link;
}
