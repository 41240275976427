@import '../../../../styles/variables';

.text {
	font: {
		family: $openSans;
		size: 14px;
		weight: $light;
	}
	line-height: 1.5;
	color: $black;

	&.roboto {
		font: {
			family: $roboto;
		}
	}

	ul {
		margin-bottom: 0;
		padding-left: 14px;

		li {
			&::marker {
				font-size: 10px;
			}

			ul {
				margin-left: 16px;
				padding-left: 14px;

				li {
					&::marker {
						font-size: 10px;
					}
				}
			}

			p {
				margin: 0;
			}
		}
	}
}
