@import '../../../../styles/variables';

.modal-wrapper {
	z-index: 10;
	
	.calendar-link-popup {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 20px;
		min-width: 364px !important;
		width: 364px !important;
		padding: 32px !important;

		.wrapper {
			color: $tunaDark;

			.title {
				font-size: 20px;
				font-weight: $demiBold;
				margin-bottom: 8px;
			}

			.description {
				font-size: 19px;
				line-height: 1.3;
				margin-bottom: 32px;
			}
		}

		.modal-actions {
			margin-top: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.btn {
				margin: 0;

				& + .btn {
					margin-top: 16px;
				}

				&:disabled {
					pointer-events: none;
				}
			}
		}
	}
}
