@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.pagination {
	margin-top: 44px !important;
	margin-bottom: 0 !important;
}

.custom-weight {
	font-weight: $demiBold !important;
}

.without-border {
	border-right: none !important;
}

.empty {
	font-family: $roboto;
	font-size: 16px;
	font-weight: $normal;
	margin-top: -10px;
	margin-bottom: 8px;
}
