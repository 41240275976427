@import '../../../../styles/variables';

.value {
	font: {
		family: $roboto;
		size: 14px;
		weight: $normal;
	}
}

.wrap {
	&.hide-marks {
		:global(.rc-slider-mark-text) {
			display: none;
		}
	}
}

.label {
	margin: {
		bottom: 12px;
	}
	opacity: 0.55;
	font: {
		family: $roboto;
		size: 10px;
		weight: $bold;
	}
	color: $abbey;
	text-transform: uppercase;
}
