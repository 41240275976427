@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.wrap {
	cursor: pointer;

	.warning {
		color: $bloodyRedApricot;
		margin-left: 30px;
		font-weight: $normal;

		&:before {
			vertical-align: middle;
			content: url('../../../../../assets/icons/warning.svg');
			width: 27px;
			height: 24px;
			display: inline-block;
			margin-right: 16px;
		}
	}

	.link {
		@extend %link;
	}
}
