@import '../../../../../styles/mixins';

.crossing {
	@extend %bar;
	background: linear-gradient(
		239.57deg,
		rgba(255, 164, 150, 0.2) 20.53%,
		rgba(237, 115, 112, 0.2) 79.85%
	);
	background: rgba(255, 255, 255, 0.7);
	border: 1px dashed #2e3039;
	border-radius: 50px;

	&.preview {
		position: relative;
		width: 34px;
		height: 16px;
	}

	&:not(.failed) {
		opacity: 0.6;
	}

	&.starts-from-zero {
		border-radius: 0 50px 50px 0;
	}

	&.failed {
		border: 1px dashed #ee7470;
		background: linear-gradient(
			239.57deg,
			rgba(255, 164, 150, 0.2) 20.53%,
			rgba(237, 115, 112, 0.2) 79.85%
		);
	}
}
