@import '../../../../styles/mixins';

.wrap {
	.header {
		width: 100%;
		justify-content: space-between;
		min-height: 73px;
		padding: 20px 75px;
	}

	.header-title {
		@include absolute(50%, auto, auto, 50%);
		transform: translateX(-50%) translateY(-50%);
	}

	&__content {
		width: 100%;
		height: 100%;
		padding: 0 !important;
	}

	.container {
		margin: {
			top: 56px;
			bottom: 40px;
		}
		padding-left: 0;
		padding-right: 0;

		&.with-link-success {
			margin-top: 40px;
		}
	}

	.messaging,
	.success {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100vh - 200px);
	}

	.messaging {
		.link-success {
			font-style: italic;
			font-size: 14px;
			margin-bottom: 32px;
		}

		.top-section {
			max-width: 328px;
			margin-bottom: 32px;

			&.with-separator {
				position: relative;
				padding-bottom: 40px;

				&:after {
					content: '';
					position: absolute;
					width: 609px;
					bottom: 0;
					left: 0;
					border-bottom: 1px solid $silver;

					@include breakpoint($mobile, $tablet) {
						width: 100%;
					}
				}
			}
		}

		.error {
			margin-top: 16px;
		}
	}

	.actions {
		padding-bottom: 60px;
		display: flex;
		justify-content: center;
	}

	.success {
		.info {
			.message {
				font-size: 22px;
				color: $tunaDark;
				margin-bottom: 24px;
			}
		}

		.btn {
			align-self: center;
		}
	}

	.names-section {
		&.multiple {
			border-bottom: 1px solid $silver;
			padding: 12px 0;
			margin-bottom: 40px;

			.label {
				color: $abbey;
				font-size: 10px;
				font-weight: $semiBold;
				text-transform: uppercase;
				margin-bottom: 8px;
				opacity: 0.5;
			}
		}
	}

	.success,
	.names-section {
		.candidate {
			font-size: 16px;
			font-weight: $demiBold;
		}

		.candidates {
			.candidate {
				margin-right: 4px;
			}

			.others-names {
				padding-top: 24px;

				.name {
					font-size: 14px;
					font-weight: $normal;

					& + .name {
						margin-top: 10px;
					}
				}
			}
		}

		.candidate,
		.candidates {
			&.for-success {
				padding: 24px 0;
				border-top: 1px solid $silver;
				border-bottom: 1px solid $silver;

				.label {
					display: none;
				}
			}
		}
	}
}
