@import '../../../styles/variables';

.map {
	width: 100%;
	height: 100%;
	border: {
		top: 1px solid $silver;
		bottom: 1px solid $silver;
	}
	font: {
		size: 0;
	}

	path:focus {
		outline: none;
	}

	.only-state {
		stroke: transparent;
	}
}
